$bp-phones: "only screen and (min-width: 0px)" !default;
$bp-bigger-phones: "only screen and (min-width: 375px)" !default;
$bp-all-buttons-visible: "only screen and (min-width: 620px)" !default;
$bp-tablets: "only screen and (min-width: 768px)" !default;
$bp-tablets-landscape: "only screen and (min-width: 1024px)" !default;
$bp-desktop: "only screen and (min-width: 1200px)" !default;
$bp-desktop-wide: "only screen and (min-width: 1600px)" !default;

$bp-tablesaw-stacked: 600px !default;

// Breakpoints
$g-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1920px
);

$g-fluid-min-bp: map-get($g-breakpoints, md);
$g-fluid-max-bp: map-get($g-breakpoints, xxxl);

@mixin bp($point) {
  @if $point == phone {
    @media #{$bp-phones} {
      @content;
    }
  } @else if ($point == bigger-phones) {
    @media #{$bp-bigger-phones} {
      @content;
    }
  } @else if ($point == all-buttons) {
    @media #{$bp-all-buttons-visible} {
      @content;
    }
  } @else if ($point == tablet) {
    @media #{$bp-tablets} {
      @content;
    }
  } @else if ($point == tablet_landscape) {
    @media #{$bp-tablets-landscape} {
      @content;
    }
  } @else if ($point == desktop) {
    @media #{$bp-desktop} {
      @content;
    }
  } @else if ($point == desktop_wide) {
    @media #{$bp-desktop-wide} {
      @content;
    }
  } @else {
    @media only screen and (min-width: #{$point}) {
      @content;
    }
  }
}

@mixin bp-default-sizes(
  $element,
  $phone: null,
  $tablet: null,
  $tablet_landscape: null,
  $desktop: null,
  $desktop_wide: null
) {
  @include multi-bp(
    $element,
    (
      phone: $phone,
      tablet: $tablet,
      tablet_landscape: $tablet_landscape,
      desktop: $desktop,
      desktop_wide: $desktop_wide
    )
  );
}

@mixin multi-bp($attribute, $size_values) {
  $sizes: map_keys($size_values);
  @each $size in $sizes {
    $size_value: map_get($size_values, $size);
    @if ($size_value != null) {
      @include bp($size) {
        #{$attribute}: #{$size_value};
      }
    }
  }
}
