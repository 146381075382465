// -------------------------------------------
// -- Titles (h1, h2, ...)
// -------------------------------------------

.reader__body {
  line-height: $body--p-line-height;

  p {
    margin: $body--p-margin;
    color: $body--p-color;
    font-size: $body--p-font-size;
    font-weight: $body--p-font-weight;
    line-height: $body--p-line-height;
    font-family: $body--p-font-family;
  }
  p.lead {
    margin: $body--p-lead-margin;
    color: $body--p-lead-color;
    font-size: $body--p-lead-font-size;
    font-weight: $body--p-lead-font-weight;
    line-height: $body--p-lead-line-height;
    font-family: $body--p-lead-font-family;
  }
  p.title {
    margin: $body--p-title-margin;
    color: $body--p-title-color;
    font-size: $body--p-title-font-size;
    font-weight: $body--p-title-font-weight;
    line-height: $body--p-title-line-height;
    font-family: $body--p-title-font-family;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 0;
    margin: 0;
    position: relative;
  }

  h1 {
    margin: $body--h1-margin;
    color: $body--h1-color;
    font-size: $body--h1-font-size;
    font-weight: $body--h1-font-weight;
    line-height: $body--h1-line-height;
    font-family: $body--h1-font-family;
  }

  h2 {
    margin: $body--h2-margin;
    color: $body--h2-color;
    font-size: $body--h2-font-size;
    font-weight: $body--h2-font-weight;
    line-height: $body--h2-line-height;
    letter-spacing: $body--h2-letter-spacing;
    font-family: $body--h2-font-family;
  }

  .container {
    h2 {
      &:first-child {
        margin-top: 0 !important;
      }
    }
  }

  h3 {
    margin: $body--h3-margin;
    color: $body--h3-color;
    font-size: $body--h3-font-size;
    font-weight: $body--h3-font-weight;
    line-height: $body--h3-line-height;
    font-family: $body--h3-font-family;
  }

  h4 {
    margin: $body--h4-margin;
    color: $body--h4-color;
    font-size: $body--h4-font-size;
    font-weight: $body--h4-font-weight;
    line-height: $body--h4-line-height;
    font-family: $body--h4-font-family;
  }

  h5 {
    margin: $body--h5-margin;
    color: $body--h5-color;
    font-size: $body--h5-font-size;
    font-weight: $body--h5-font-weight;
    line-height: $body--h5-line-height;
    font-family: $body--h5-font-family;
  }

  h6 {
    margin: $body--h6-margin;
    color: $body--h6-color;
    font-size: $body--h6-font-size;
    font-weight: $body--h6-font-weight;
    line-height: $body--h6-line-height;
    font-family: $body--h6-font-family;
  }
}

@media screen and (min-width: $body-typography-breakpoint) {
  .reader__body {
    &,
    p {
      margin: $body--p--bigger-margin;
      color: $body--p--bigger-color;
      font-size: $body--p--bigger-font-size;
      font-weight: $body--p--bigger-font-weight;
      line-height: $body--p--bigger-line-height;
    }
    p.lead {
      margin: $body--p-lead--bigger-margin;
      color: $body--p-lead--bigger-color;
      font-size: $body--p-lead--bigger-font-size;
      font-weight: $body--p-lead--bigger-font-weight;
      line-height: $body--p-lead--bigger-line-height;
    }
    p.title {
      margin: $body--p-title--bigger-margin;
      color: $body--p-title--bigger-color;
      font-size: $body--p-title--bigger-font-size;
      font-weight: $body--p-title--bigger-font-weight;
      line-height: $body--p-title--bigger-line-height;
    }

    h1 {
      margin: $body--h1--bigger-margin;
      color: $body--h1--bigger-color;
      font-size: $body--h1--bigger-font-size;
      font-weight: $body--h1--bigger-font-weight;
      line-height: $body--h1--bigger-line-height;
    }

    h2 {
      margin: $body--h2--bigger-margin;
      color: $body--h2--bigger-color;
      font-size: $body--h2--bigger-font-size;
      font-weight: $body--h2--bigger-font-weight;
      line-height: $body--h2--bigger-line-height;
      letter-spacing: $body--h2--bigger-letter-spacing;
    }

    h3 {
      margin: $body--h3--bigger-margin;
      color: $body--h3--bigger-color;
      font-size: $body--h3--bigger-font-size;
      font-weight: $body--h3--bigger-font-weight;
      line-height: $body--h3--bigger-line-height;
    }

    h4 {
      margin: $body--h4--bigger-margin;
      color: $body--h4--bigger-color;
      font-size: $body--h4--bigger-font-size;
      font-weight: $body--h4--bigger-font-weight;
      line-height: $body--h4--bigger-line-height;
    }

    h5 {
      margin: $body--h5--bigger-margin;
      color: $body--h5--bigger-color;
      font-size: $body--h5--bigger-font-size;
      font-weight: $body--h5--bigger-font-weight;
      line-height: $body--h5--bigger-line-height;
    }

    h6 {
      margin: $body--h6--bigger-margin;
      color: $body--h6--bigger-color;
      font-size: $body--h6--bigger-font-size;
      font-weight: $body--h6--bigger-font-weight;
      line-height: $body--h6--bigger-line-height;
    }
  }
}
