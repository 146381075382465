.v-#{$design-version} {
  .reader-horizontal-navigation {
    display: grid;

    grid-template-columns: 100%;
    grid-template-rows: auto auto 1fr;

    height: 100vh;
  }

  .reader__header.horizontal-header {
    grid-row: 1;
    position: static;
  }

  .horizontal-navigation {
    grid-row: 2;
  }

  .horizontal-content.reader__body {
    grid-row: 3;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;

    .reader__content__wrapper {
      height: 100%;
    }

    &.navigation-shown .reader__content__wrapper {
      @include override {
        margin-left: 0;
      }
    }
  }
}

body,
html {
  height: 100%;
}
