@if $body--container-activate {
  .reader__body {
    .container {
      margin: $body--container-margin;
      padding: $body--container-padding;
    }

    .container--last {
      padding: $body--container--last-padding;
      margin: $body--container--last-margin;
    }
  }

  .reader__body {
    @media screen and (min-width: 600px) {
      .container--last {
        padding: $body--container--last--bigger-padding;
        margin: $body--container--last--bigger-margin;
      }
    }
  }
}
