@if ($marginal-enabled) {

    .marginal {
    display: block;
    padding: $marginal-padding;
    margin: $marginal-margin;
    cursor: pointer;
    z-index: $content-z-index;

    @if ($marginal-totheside-enabled) {
        @media screen and (min-width: $content-large-side-padding-trigger) {
            position: absolute;
            width: $marginal--wide-width;
            padding: $marginal--wide-padding;
            margin: $marginal--wide-margin;
            text-align: $marginal--wide-text-align;
        }
    }

    .text,
    .link {
        color: $marginal-color;
        line-height: $marginal--textlink-line-height;

        @if ($marginal-totheside-enabled) {
            @media screen and (min-width: $content-large-side-padding-trigger) {
                display: block;
                font-size: $marginal--wide--textlink-font-size;
                line-height: $marginal--wide--textlink-line-height;
            }
        }

        a {
        color: $marginal-color;
        text-decoration: $marginal--textlink--a-text-decoration;
        }
    }

    .link {
        @if ($marginal-totheside-enabled) {
            @media screen and (min-width: $content-large-side-padding-trigger) {
                font-weight: $marginal--wide--textlink--a-font-weight;
            }
        }
    }

    }

    .marginal--external {
    background: $marginal--external-background;
    background-size: $marginal--external-background-size;

    @if ($marginal-totheside-enabled) {
        @media screen and (min-width: $content-large-side-padding-trigger) {
            background: $marginal--wide--external-background;
            background-size: $marginal--wide--external-background-size;
        }
    }
    }

    .marginal--internal {
    background: $marginal--internal-background;
    background-size: $marginal--internal-background-size;

    @if ($marginal-totheside-enabled) {
        @media screen and (min-width: $content-large-side-padding-trigger) {
            background: $marginal--wide--internal-background;
            background-size: $marginal--wide--internal-background-size;
        }
    }
    }
}

.body--followed-by-marginal {
    @if ($marginal-totheside-enabled) {
        @media screen and (min-width: $content-large-side-padding-trigger) {
            min-height: $marginal--bodyfollowedbymarginal-min-height;
        }
    } @else {
        min-height: auto;
    }
}

.reader__content {
    @media screen and (min-width: $content-large-side-padding-trigger) {
        padding: $content-padding-normal $content-padding-side-large;
    }
}
