.spinner__loader {
  position: relative;

  width: $spinner-edge-size;
  height: $spinner-edge-size;
  margin: $spinner-margin;

  font-size: $spinner-font-size;
  text-indent: $spinner-text-indent;

  border-radius: 50%;
  background: $spinner-background;
  background: $spinner-background-gradient;

  &.animate {
    animation: load_spinner_loader_keyframes 0.8s infinite linear;
    transform: translateZ(0);
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;

    width: 50%;
    height: 50%;

    background: $spinner-background;
    border-radius: 100% 0 0 0;

    content: '';
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    width: 75%;
    height: 75%;
    margin: auto;

    background: $white;
    border-radius: 50%;

    content: '';
  }
}

@keyframes load_spinner_loader_keyframes {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
