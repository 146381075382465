.reader__search__menu {
  padding: $search--padding;
  border-bottom: $search--border-bottom;
  border-right: $search--border-right;

  a {
    text-decoration: none;
  }

  .input__group--search {
    margin: $search--input-margin;

    .input--search {
      font-family: $search--input-font-family;
      font-weight: $search--input-font-weight;
    }
  }

  .search-results-title {
    padding: $search--resultstitle-padding;

    h3 {
      @if $search--resultstitle--h3-color {
        color: $search--resultstitle--h3-color;
      }
      @if $search--resultstitle--h3-font-size {
        font-size: $search--resultstitle--h3-font-size;
      }
      @if $search--resultstitle--h3-font-weight {
        font-weight: $search--resultstitle--h3-font-weight;
      }
      margin: $search--resultstitle--h3-margin;
      text-align: $search--resultstitle--h3-text-align;
    }
  }

  .results {
    .result {
      padding: $search--results--result-padding;
      border-bottom: $search--results--result-border-bottom;
      cursor: $search--results--result-cursor;
      margin-left: 1px;
      word-break: break-word;
      margin-right: 1px;

      &:hover {
        transition: background 0.25s ease-in-out;
        background-color: $search--results-result-hover;
      }

      p {
        margin-bottom: $search--results--result--p-margin;
        @if $search--results--result--p-color {
          color: $search--results--result--p-color;
        }
        font-size: $search--results--result-p-font-size;
      }

      h4 {
        @if $search--results--title-color {
          color: $search--results--title-color;
        }
        @if $search--results--title-font-weight {
          font-weight: $search--results--title-font-weight;
        }
        @if $search--results--title-font-family {
          font-family: $search--results--title-font-family;
        }
      }
    }
  }
}

.search-highlight {
  color: $search--results-result--highlight-color;
  padding: $search--results-result--highlight-padding;
  background: $search--results-result--highlight-background;
  font-style: $search--results-result--highlight-font-style;
}
