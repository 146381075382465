.input__group--search {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.input--search {
  outline: none;

  font-weight: $input--search-font-weight;

  padding: $input--search-padding;
  width: calc(100% - #{$input--search--button-width});

  background-color: $input--search-background-color;

  border: $input--search-border;
  border-right-width: $input--search-border-right-width;
  border-radius: $input--search-border-radius;

  -webkit-appearance: none;

  &:focus {
    border: $input--search--focus-border;
    border-right-width: $input--search-border-right-width;
  }
}

//noinspection CssOptimizeSimilarProperties
.input--search-button {
  cursor: pointer;
  color: $input--search--button-color;
  flex: 1 0 auto;
  background: $input--search--button-background-image;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: $input--search--button-background-size;

  width: $input--search--button-width;

  border: $input--search--button-border;
  border-radius: $input--search--button-border-radius;

  outline: $input--search--button-outline;

  padding: 0;

  &:hover {
    background: $input--search--button-background-image--hover;
  }
}
