@import '../../config/base/_base.scss';

@at-root {
  @font-face {
    font-family: '#{$icon-font}';
    src: url('#{$icon-font-font-path}/icon-font.woff2?1b1q3y') format('woff2'),
      url('#{$icon-font-font-path}/icon-font.ttf?1b1q3y') format('truetype'),
      url('#{$icon-font-font-path}/icon-font.woff?1b1q3y') format('woff'),
      url('#{$icon-font-font-path}/icon-font.svg?1b1q3y#icon-font') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

.icon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icon-font}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@at-root {
  .icon-font-home,
  .icon-font-content-close,
  .icon-font-content {
    font-family: '#{$icon-font}';
  }

  .icon-font-home {
    &:before {
      content: $icon-font-home;
    }
  }
  .icon-font-content-close {
    &:before {
      content: $icon-font-content-close;
    }
  }
  .icon-font-content {
    &:before {
      content: $icon-font-content;
    }
  }

  .icon-font-share {
    &:before {
      content: $icon-font-share;
    }
  }
  .icon-font-download {
    &:before {
      content: $icon-font-download;
    }
  }
  .icon-font-add {
    &:before {
      content: $icon-font-add;
    }
  }
  .icon-font-navigation {
    &:before {
      content: $icon-font-navigation;
    }
  }
  .icon-font-navigation-close {
    &:before {
      content: $icon-font-navigation-close;
    }
  }
  .icon-font-close {
    &:before {
      content: $icon-font-close;
    }
  }
  .icon-font-calendar {
    &:before {
      content: $icon-font-calendar;
    }
  }
  .icon-font-checkmark {
    &:before {
      content: $icon-font-checkmark;
    }
  }
  .icon-font-dropdown {
    &:before {
      content: $icon-font-dropdown;
    }
  }
  .icon-font-language {
    &:before {
      content: $icon-font-language;
    }
  }
  .icon-font-mail {
    &:before {
      content: $icon-font-mail;
    }
  }
  .icon-font-play {
    &:before {
      content: $icon-font-play;
    }
  }
  .icon-font-previous-chevron {
    &:before {
      content: $icon-font-previous-chevron;
    }
  }
  .icon-font-next-chevron {
    &:before {
      content: $icon-font-next-chevron;
    }
  }
  .icon-font-left {
    &:before {
      content: $icon-font-left;
    }
  }
  .icon-font-right {
    &:before {
      content: $icon-font-right;
    }
  }
  .icon-font-expand {
    &:before {
      content: $icon-font-expand;
    }
  }
  .icon-font-collapse {
    &:before {
      content: $icon-font-collapse;
    }
  }
  .icon-font-search {
    &:before {
      content: $icon-font-search;
    }
  }
  .icon-font-search-line {
    &:before {
      content: $icon-font-search-line;
    }
  }
  .icon-font-twitter {
    &:before {
      content: $icon-font-twitter;
    }
  }
  .icon-font-xing {
    &:before {
      content: $icon-font-xing;
    }
  }
  .icon-font-youtube {
    &:before {
      content: $icon-font-youtube;
    }
  }
  .icon-font-instagram {
    &:before {
      content: $icon-font-instagram;
    }
  }
  .icon-font-linkedin {
    &:before {
      content: $icon-font-linkedin;
    }
  }
  .icon-font-facebook {
    &:before {
      content: $icon-font-facebook;
    }
  }
  .icon-font-googleplus {
    &:before {
      content: $icon-font-googleplus;
    }
  }
  .icon-font-document {
    &:before {
      content: $icon-font-document;
    }
  }
}
