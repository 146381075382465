@import './colors';

$table--linedark-border-bottom: 2px solid $grey-another-one;
$table--linelight-border-bottom: 1px solid $grey-medium;
$table--linethin-border-bottom: 2px solid $grey-light;
$table--highlight-color: $black;
$table--highlight-font-weight: 700;
$table--highlight-border-right: none;
$table--highlight-border-left: none;
$table--highlight-background: $grey-another-one-12;
$table--color-color: $grey;
$table--desc-light-color: $grey-light;
$table-row-hover-background-color: $grey-another-one-12;
$table--desclight-color: $grey-light;
$table--th-colspan-text-alignment: revert;
$scroller--enable-scroll-shadows: true;
$stackable--table-tr--td-vertical-align: bottom;
$table--linetitle-padding-top: 24px;

$table-border: 12px solid white;

@mixin create-2023-table-highlights {
  .gb-table {
    :not(.highlight):has(+ .highlight) + .highlight {
      // first element of a group
      // border-left: 12px solid white !important;
      padding-left: 10px !important;
    }

    .highlight:has(+ :not(.highlight)) {
      // last group of highlight
      // border-right: 12px solid white !important;
    }
  }
}
