@mixin override() {
  &.override {
    @content;
  }
}

@mixin override-md() {
  @include media-breakpoint-up(md) {
    &.override {
      @content;
    }
  }
}

@mixin override-lg() {
  @include media-breakpoint-up(lg) {
    &.override {
      @content;
    }
  }
}

@mixin override-reader-tabs() {
  @include bp-reader-tabs {
    &.override {
      @content;
    }
  }
}

@mixin override-xl() {
  @include media-breakpoint-up(xl) {
    &.override {
      @content;
    }
  }
}
