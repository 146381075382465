@mixin generate-quotes() {
  .container > p.swisscom-AB-zitat {
    @include f((margin-left, margin-right), 32px, 88px);
    @include f((margin-top, margin-bottom), 32px, 64);
    @include f((font-size), 24px, 48px);

    color: $blue-accessible;
    font-weight: 400;
  }
}
