@mixin bp-xs() {
  @include media-breakpoint-up(xs) {
    @content;
  }
}

@mixin bp-md() {
  @include media-breakpoint-up(md) {
    @content;
  }
}

@mixin bp-lg() {
  @include media-breakpoint-up(lg) {
    @content;
  }
}

@mixin bp-reader-tabs {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

@mixin bp-xl() {
  @include media-breakpoint-up(xl) {
    @content;
  }
}

@mixin bp-max-md() {
  @include media-breakpoint-down(md) {
    @content;
  }
}

@mixin bp-max-lg() {
  @include media-breakpoint-down(lg) {
    @content;
  }
}

@mixin bp-max-xl() {
  @include media-breakpoint-down(xl) {
    @content;
  }
}

@mixin multi-bp($property, $values) {
  @each $size, $value in $values {
    @include media-breakpoint-up($size) {
      #{$property}: $value;
    }
  }
}
