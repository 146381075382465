.grid {
  display: grid;
  grid-template-columns: repeat($grid-columns, 1fr);

  @include grid-generate-classes($grid-rows, '.row', grid-row);
  @include grid-generate-classes($grid-columns, '.col_start', grid-column-start);
  @include grid-generate-start($grid-columns, '.col_width');
  
  @each $bp-name, $value in $grid-breakpoints {
    @media only screen and (min-width: $value) {
      @include grid-include-value-map($bp-name, $grid-gutter-widths, grid-column-gap);
      @include grid-include-value-map($bp-name, $grid-paddings, padding);
      @include grid-include-value-map($bp-name, $grid-max-widths, max-width);
      
      $suffix: '_#{$bp-name}';
      @include grid-generate-classes($grid-rows, '.row', grid-row, $suffix);
      @include grid-generate-classes($grid-columns, '.col_start', grid-column-start, $suffix);
      @include grid-generate-start($grid-columns, '.col_width', $suffix);
    }
  }
}

.grid__center__horizontal {
  margin: 0 auto;
}

.grid__full__width {
  width: 100%;
}
