/*
 |--------------------------------------------------------------------------
 |  Fluid Type
 |--------------------------------------------------------------------------
 */
/**
    More Infos: http://madebymike.com.au/writing/fluid-type-calc-examples/

    Single property
    html {
      @include fluid(14px, 18px, font-size);
    }

    Multiple properties with same values
    h1 {
      @include fluid(20px, 100px, padding-bottom padding-top);
    }
 */

@use 'sass:math';


@function strip-unit-custom($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return math.div($number, ($number * 0 + 1));
  }

  @return $number;
}

@mixin fluid($properties, $min-value, $max-value, $min-vw: $g-fluid-min-bp, $max-vw: $g-fluid-max-bp, $add-default-query: true) {
  @if ($properties != null) {
    @if ($add-default-query) {
      @each $property in $properties {
        #{$property}: $min-value;
      }
    }

    @if (strip-unit-custom($max-value - $min-value) != 0) {
      @media screen and (min-width: $min-vw) {
        @each $property in $properties {
          #{$property}: calc(
            #{$min-value} +
              #{strip-unit-custom($max-value - $min-value)} *
              ((100vw - #{$min-vw}) / (#{strip-unit-custom($max-vw - $min-vw)}))
          );
        }
      }

      @media screen and (min-width: $max-vw) {
        @each $property in $properties {
          #{$property}: $max-value;
        }
      }
    }
  }
}

// Shorthand Version
@mixin f($args...) {
  @include fluid($args...);
}
@mixin fmap($prop, $minmax) {
  @include fluid($prop, nth($minmax, 1), nth($minmax, 2));
}
