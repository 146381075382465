@import '../../../../../node_modules/tablesaw/src/tables.stack-mixin';

.reader__body {
  .gb-table {
    width: 100%;
    border-collapse: collapse;
    font-family: $table--font-family;

    td,
    th {
      min-width: var(--fixed-width, unset);
    }

    img {
      display: inline-block;
      margin: 0;
    }

    th {
      vertical-align: $table--header-vertical-align;
      font-weight: $table--headerrow-font-weight !important;
      color: $table--headerrow-color !important;

      &.highlight {
        @if $table--highlight-th-top-border {
          border-top: $table--highlight-th-top-border;
        }
      }
    }

    th,
    tr {
      font-size: $table--headerrow-font-size;

      &.line-dark {
        border-bottom: $table--linedark-border-bottom;
      }

      &.line-medium {
        border-bottom: $table--linemedium-border-bottom;
      }

      &.line-light {
        border-bottom: $table--linelight-border-bottom;
      }

      &.line-thin {
        border-bottom: $table--linethin-border-bottom;
      }

      &.reader-table-noline {
        border-bottom: 0 !important;
      }
    }

    tr {
      &.row-spacing {
        @media only screen and (min-width: $bp-tablesaw-stacked) {
          height: 12px;
          & > td {
            padding: 0 !important;
            > * {
              display: none !important;
            }
          }
        }
      }
      &.line-title {
        td {
          padding-top: $table--linetitle-padding-top !important;
          padding-bottom: $table--linetitle-padding-bottom !important;

          @if $table--linetitle-border-bottom {
            border-bottom: $table--linetitle-border-bottom !important;
          }
        }
      }

      &.line-footnote {
        border-bottom: $table--line-footnote-border-bottom;
      }
    }

    th,
    td {
      padding: $table--row-padding;
      color: $stackable--table--thtd-color;

      &.superscript {
        padding: $table--row-padding-superscript;
      }

      &.reader-table-noline {
        border-bottom: 0 !important;
      }
      line-height: $table-line-height;
    }

    .footnote {
      border-bottom: $table--footnote-border-bottom;

      .tablesaw-cell-content {
        & > div {
          text-align: $table--cellcontent-text-align;
        }
      }
    }

    .footnote-title {
      display: $table--footnote-title-display;
      font-family: $table--footnote-title-font-family;
      font-weight: $table--footnote-title-font-weight;
    }

    tr {
      &.suppress {
        display: none !important;
      }

      td:first-child {
        text-align: $table--row-firstchild-text-align;
      }
    }

    .color {
      padding-top: $table--color-padding-top;
      color: $table--color-color;
    }

    .color2 {
      color: $table--color-color;
    }

    .bold {
      font-weight: $table--bold-font-weight !important;
    }

    .desc {
      vertical-align: $table--desc-vertical-align;
    }

    .desc-light {
      color: $table--desclight-color;
      text-align: $table--desc-light-text-align;
      font-size: $table--desc-light-font-size;
      font-weight: $table--desc-light-font-weight;
    }

    .number {
      text-align: $table--number-text-align;
      color: $table--number-color;
      font-weight: $table--number-font-weight;
    }

    .icon {
      text-align: center;
      padding: 5px 10px;
    }

    .highlight {
      color: $table--highlight-color;
      font-weight: $table--highlight-font-weight;
      background: $table--highlight-background;
      border-right: $table--highlight-border-right;
      border-left: $table--highlight-border-right;

      &.line-thin {
        border-right: $table--highlight--linethin-border-right;
        border-left: $table--highlight--linethin-border-left;
      }
    }

    th,
    td {
      $first: 2;
      $last: 10;
      @for $i from $first through $last {
        &.highlight.highlight-span-#{$i} {
          $selector: '';

          @for $x from 1 through $i {
            &#{$selector} {
              @if $x > 1 {
                border-left-style: none;
              }
              @if $x < $i {
                border-right-style: none;
              }
            }

            $selector: append($selector, ' + * + *.highlight');
          }
        }
      }
    }

    @for $i from 1 through 100 {
      .width#{$i} {
        width: unquote('#{$i}%');
      }
    }

    .firstnote {
      padding-top: $table--firstnote-padding-top;
    }
  }

  .gb-table.gb-table-fixed-width {
    table-layout: fixed;
    min-width: 0px !important;
    width: auto !important;

    & th,
    & td {
      word-break: break-word;
      width: auto;
      padding-right: $table--fixed--td-padding-right;
    }

    & .highlight {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media screen and (max-width: $bp-tablesaw-stacked - 1px) {
    .gb-table {
      .desc--unit,
      .cell--empty {
        display: none;
      }

      .line--title {
        .tablesaw-cell-label {
          display: none;
        }
      }
    }
  }

  @if ($scroller--enable-scroll-shadows) {
    @include table-generate-shadows('.scroller');
  }

  @if ($scroller--enable-stackable-scroll-shadows) {
    @include table-generate-shadows('.stackable');
  }

  .scroller {
    overflow-x: auto;
    margin-bottom: $content-bottom-margin;

    .gb-table {
      min-width: $scroller--table-min-width;
      width: calc(100% - 2px);

      th.desc {
        text-align: $scroller--table-thdesc-text-align;
      }
    }
  }

  .scroller.stackable,
  .stackable.scroller {
    .gb-table {
      min-width: 0;
      @include bp($bp-tablesaw-stacked) {
        min-width: $scroller--table-min-width;
      }
    }
  }

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .scroller {
      overflow-x: auto;
    }
  }

  @-moz-document url-prefix() {
    .scroller {
      overflow-x: auto;
    }
  }

  .stackable {
    @include tablesaw-stack($bp-tablesaw-stacked);

    margin-bottom: $content-bottom-margin;
    padding: 0 1px 0 1px;

    overflow-x: auto;

    .gb-table {
      td:not(.superscript),
      th:not(.superscript) {
        padding: $stackable--table--thtd-padding;
      }

      .tablesaw-cell-content {
        vertical-align: $stackable--table-tr--td-vertical-align;
      }

      tr {
        td:first-child {
          padding-top: $stackable--table--tr--tdfirstchild-padding-top;

          .tablesaw-cell-label {
            display: none;
          }

          .tablesaw-cell-content {
            width: 100%;
            max-width: 100%;
          }
        }

        td:last-child {
          padding-bottom: $stackable--table--tr--tdlastchild-padding-bottom;
        }
      }

      .superscript {
        display: none;
      }

      .line-title {
        td:not(:first-child) {
          display: none;
        }
      }

      .highlight {
        border-right: $stackable--table--highlight-border-right;
        color: $table--highlight-color;
      }
    }
  }

  @media screen and (min-width: $bp-tablesaw-stacked) {
    .stackable {
      .gb-table {
        td:not(.superscript),
        th:not(.superscript) {
          padding: $table--row-padding;
        }

        tr {
          td:first-child {
            padding-top: $table--row-normal-top-padding;
          }

          td:last-child {
            padding-bottom: $table--row-normal-bottom-padding;
          }
        }

        th.desc {
          text-align: $stackable--table--thdesc-text-align;
        }

        .superscript {
          display: table-cell;
        }

        .highlight {
          border-right: $stackable--table--highlight--wide--border-right;
          color: $table--highlight-color;
        }

        .line-title {
          td:not(:first-child) {
            display: table-cell;
          }
        }
      }
    }
  }

  /* The swisscom marginal ensures that the padding for the reader content contains x pixels to the right and left. */
  @media screen and (min-width: $content-large-side-padding-trigger) {
    .scroller,
    .stackable {
      &.oversize {
        margin-right: $scroller--stackable--oversize-margin-right;
      }
    }
  }
}

.reader__body {
  .gb-table {
    tr {
      &:hover {
        td {
          background-color: $table-row-hover-background-color;
          transition: background-color 100ms linear;
        }
      }
    }
  }
}

.reader__body {
  .gb-table {
    @for $font-size from 11 through 18 {
      &.gb-table-fixed-font-#{$font-size} {
        th,
        td {
          font-size: #{$font-size}px;
          line-height: 1.2;
        }
      }
    }
  }
}

.reader__body {
  .gb-table {
    th,
    td {
      &.aligned-bottom {
        vertical-align: bottom;
      }
      &.aligned-middle {
        vertical-align: middle;
      }
      &.aligned-top {
        vertical-align: top;
      }
    }
  }
}
