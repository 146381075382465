.reader-lead {
  margin: $lead-margin !important;

  color: $lead-color !important;

  font-size: $lead-font-size !important;
  font-weight: $lead-font-weight !important;
  line-height: $lead-line-height !important;
  font-style: $lead-font-style !important;
}

@media screen and (min-width: $content-max-width + (4 * $lead-padding)) {
  .reader-lead {
    border-left: $lead-wide-border !important;
    padding-left: $lead-padding !important;
    margin-left: -$lead-padding !important;
  }
}
