@import '../../../base/_mixins/fluid';

// import the overwrites
@import 'overwrites/manifest';

@import '../../../base/1.0/modules/base';

@import '../../../base/2.0/global-horizontal-layout';

// custom pure css stuff
.v-#{$design-version} {
  // import the module manifest from the correct base version
  //  and create the css
  @import '../../../base/1.0/modules/common';

  // import the module manifest from the correct base version
  //  and create the css
  @import '../../../base/1.0/modules/reports';

  @include generate-hint();
  @include generate-swisscom-images();
  @include generate-swisscom-lead();
  @include generate-swisscom-marginal();
  @include generate-swisscom-person();
  @include generate-quotes();
  @include generate-custom-font();
  @include generate-swisscom-sharing();
  @import './essential-topics/essential-topics-grid';
  @import './overwrites/2022-additions';

  *[data-shortlinkid],
  *[data-shortlinkurl] {
    @extend a;
  }

  .reader__body {
    .swisscom-corporate-governance-name {
      color: $blue-accessible;
    }

    .swisscom-corporate-governance-nation {
      font-style: italic;
    }
  }

  // 2017 line-title

  .line-title {
    border-bottom: 2px solid $grey-another-one;
  }

  // about-fact 25pt + p box body, --> hide

  .swisscom-about-header-blue,
  .swisscom-about-value-blue,
  .swisscom-about-unit,
  .swisscom-about-box-fact,
  .swisscom-about-box-fact-title {
    display: none;
  }

  $margin-h3-titles: 0 0 0.8rem 0 !important;

  .swisscom-about-box-fact-title {
    margin-bottom: 0 !important;
  }

  .swisscom-about-header-navy,
  .swisscom-about-header-grey,
  .swisscom-about-box-fact-title,
  .swisscom-about-header {
    font-weight: bold !important;
    margin-bottom: 0 !important;
  }

  .swisscom-about-value-navy-36pt,
  .swisscom-about-value-navy-30pt,
  .swisscom-about-value-grey-36pt,
  .swisscom-about-value-grey-30pt,
  .swisscom-about-value-grey-29pt,
  .swisscom-about-value-navy-25pt,
  .swisscom-about-value-grey-25pt,
  .swisscom-about-value-navy-21pt,
  .swisscom-about-value-navy-30pt_small,
  .swisscom-about-value {
    @extend h3;
    margin-bottom: 0 !important;
  }

  .swisscom-about-footnote,
  .esg-body-text-footnote {
    &,
    * {
      font-size: 16px !important;
    }
  }

  .swisscom-about-text-enum {
    margin-bottom: 0 !important;
    & > .blue {
      margin-right: 8px;
    }
  }

  .swisscom-about-text-enum + .swisscom-about-header-grey {
    margin-top: 30px;
  }

  .swisscom-about-text + .swisscom-about-text-enum {
    margin-top: -20px;
  }

  // VR

  .swisscom-corporate-governance-name {
    @extend h5;
    color: $blue-accessible !important;
    font-weight: bold !important;
    margin-bottom: 0 !important;
  }
  .swisscom-corporate-governance-function,
  .swisscom-corporate-governance-subtitle {
    font-weight: bold !important;
  }

  // geschaeftsuebersicht, titel mapping

  .swisscom-about-box-title-1zeile,
  .swisscom-about-box-title-2zeilen {
    @extend h3;
    margin: $margin-h3-titles;

    & + .swisscom-about-box-subtitle {
      margin-top: 2rem;
    }
  }

  .swisscom-about-box-subtitle {
    @extend h5;
  }

  // indicators

  @mixin indicator($color) {
    &::before {
      $edge: 1.4rem;
      content: ' ';
      display: inline-block;
      width: $edge;
      height: $edge;
      border-radius: 50%;
      border-width: $edge;
      background-color: $color;
      margin-top: 0.2rem;
    }
  }
  td.indicator-goal-red,
  td.indicator-goal-green {
    position: relative;
    text-align: center;

    $offset: 0.2rem;
    &::before {
      margin-right: $offset;
    }
    & sup {
      margin-right: -#{$offset * 4};
    }
  }

  td.indicator-goal-red,
  .indicator-footnote-goal-red {
    $red: #dd1122; // brand colors -> swisscom red
    @include indicator($red);
  }

  td.indicator-goal-green,
  .indicator-footnote-goal-green {
    $green: #759126; // Content colors -> Apple rollover
    @include indicator($green);
  }

  .indicator-footnote-goal-green,
  .indicator-footnote-goal-red {
    display: inline-block;
    padding-top: 0.2rem;
    margin: 0;
    margin-right: 0.4rem;
    padding: 0;
    &::before {
      margin: 0;
      display: block;
    }
  }

  .footnote > .table-legend {
    sup {
      margin-right: 0.4rem;
    }
  }

  .indicator-footnote-goal-red {
    margin-left: 0.9rem;
  }

  // sdg images in table

  .tablesaw-cell-content {
    img[alt*='SDG'] {
      margin-top: 0.35rem;
    }
  }

  // sdg-marginal

  .sdg-marginal {
    display: block;
    margin-bottom: 3rem;

    img {
      $width: 3.8rem;
      margin: 0;
      width: $width;
      height: $width;
    }

    & > a {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      @include bp(tablet) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-end;
      }

      &,
      & * {
        font-weight: normal;
        color: $black;
        line-height: 24px;
        text-decoration: none;
      }

      span {
        &,
        & b {
          line-height: 1;
        }
        padding-bottom: 0.3rem;
        border-bottom: 0.1rem solid $marginal-color;
      }

      span:first-of-type {
        border-bottom: 0;
        @include bp(tablet) {
          border-bottom: 0.1rem solid $marginal-color;
          margin-left: 1.2rem;
        }
      }
      span:last-of-type {
        @include bp(tablet) {
          margin-left: 0.4rem;
        }
      }
    }
  }

  // ul - li without bullet point
  .no-indicator,
  .noBullet {
    list-style: none !important;
    background: 0 !important;
  }

  // navigation overwrite

  .report--1 {
    .level.level--2 {
      .arrow {
        color: $grey-dark;
      }
    }
  }

  // swisscom marginal double lines

  .swisscom-marginal {
    display: block;

    & > .link .external-link {
      text-decoration: none !important;
    }
  }

  /* reader 2018 */
  .reader__navigation__menu {
    .level--3,
    .level--4,
    .level--5 {
      & .text {
        transition: all 0.25s ease-out;
      }
      &.selected .text {
        @include f((padding-top), 16px, 19px);
        @include f((padding-bottom), 16px, 19px);
      }
    }
  }

  .reader__overlay {
    margin: 0 !important;
  }

  .swisscom-person {
    img {
      max-width: 100% !important;
      margin-top: 0;
      width: auto;
      height: 80px;
    }
  }

  @include create-2023-table-highlights();

  .suppressMobile {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  span[data-glossary] {
    font-weight: inherit !important;
  }

  @media screen and (min-width: 1700px) {
    .reader__content {
      padding: 50px 200px;
    }
  }

  .stackable + .stackable,
  h3.marginTop {
    margin-top: 72px;
  }
}
