.hint {
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: auto;

  padding: $hint-padding;
  margin: $hint-margin;

  border-left: $hint-border-left;

  & > div {
    flex: 0 0 auto;
    max-width: 100%;
  }

  .box {
    margin: $hint--box-margin;
    color: $hint--box-color;
  }

  .box-text {
    margin: $hint--boxtext-margin;
    color: $hint--boxtext-color;
  }

  .box-digit,
  .box-digit-null {
    .digit {
      margin: $hint--boxdigit--digit-margin;
      color: $hint--boxdigit--digit-color;
      font-weight: $hint--boxdigit--digit-font-weight;
      font-size: $hint--boxdigit--digit-font-size;
      line-height: $hint--boxdigit--digit-line-height;
    }

    .digit-value {
      margin: $hint--boxdigit--digitvalue-margin;
      color: $hint--boxdigit--digitvalue-color;
      font-weight: $hint--boxdigit--digitvalue-font-weight;
      font-size: $hint--boxdigit--digitvalue-font-size;
      line-height: $hint--boxdigit--digitvalue-line-height;
    }
  }
}

.hint--special {
  display: block;

  height: auto;

  & > div:first-child {
    margin: $hintspecial--firstchild-margin;
  }

  & > div:nth-child(2) {
    display: inline-block;
  }

  & > div:last-child {
    display: block;

    .box-digit {
      & > .digit-value:first-child {
        margin: $hintspecial--lastchild--boxdigit--digitvalue--first-child-margin;
      }
    }
  }
}
