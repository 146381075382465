$reader--tools-enabled: true;
$reader--tools--share-border: 1px solid $blue-light;
$reader--tools--share-background: $grey-another-one-4;
$reader--tools--share-padding: 0.8rem 1.2rem 1.5rem 0.2rem;
$reader--tools--share-font-color: $grey;
$reader--tools--share--icon--active-border: $white;
$reader--tools--share--icon--hover-border: 2px solid $blue-light;
$reader--tools--share-border-width: 0.3rem 0 0 0;
$reader--tools--share-font-size: 1.4rem;
$reader--tools--share--box-shadow: none;
$reader--tools--share--button-color: $grey;

$reader--tools--share--icon-border-radius: 3px;
$reader--tools--share--icon-border: 2px solid $blue-light;
$reader--tools--share--icon-color: $blue-light;
$reader--tools--share--icon--hover-background: $blue-light;
$reader--tools--share--icon--hover-color: $white;

@mixin generate-swisscom-sharing() {
  h3.webui-popover-title {
    padding: 0px 14px !important;
  }
}
