.v-#{$design-version} {
  .reader-horizontal-navigation {
    .sidebar,
    .reader__navigation__menu {
      @include override {
        width: 100%;
        max-width: 100%;
      }
    }

    .sidebar {
      @include override {
        .tabcontrol {
          @include override {
            height: calc(100% - 54px - 44px);
          }
        }
      }

      .report-switcher__input {
        @include override-md {
          max-width: 600px;
        }
      }
    }
  }
}
