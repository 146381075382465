@mixin generate-swisscom-images() {
  .swisscom-image {
    @extend .reader-image;
    margin: 0 0 40px 0;
  }

  .swisscom-image--full {
    @extend .reader-image--full;
  }

  .swisscom-image--half {
    @extend .reader-image--half;
  }

  .row__block img {
    margin-bottom: 0;
  }
}
