.v-#{$design-version} {
  .reader-horizontal-navigation {
    .inline-search {
      z-index: 99;
      
      width: 100%;
      height: 100%;

      padding-top: 8px;

      margin: 0 40px;

      display: none;
    }

    .inline-search.visible {
      display: block;
    }

    .inline-search-input-container {
      width: 100%;
      height: 100%;

      padding: 16px 24px;

      background-color: white;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .inline-search-input {
        flex: 1;
        width: auto;
      }

      .inline-search-input-icon {
        flex: 0;
        width: 18px;
        height: 18px;

        cursor: pointer;
      }

      .icon-font-search {
        margin-right: 24px;
      }

      .icon-font-close {
        margin-left: 24px;
      }
    }

    .inline-search-input {
      border: none;
      outline: none;
      :focus {
        outline: none;
      }
    }

    .inline-search-results {
      padding: 16px 24px;

      width: 100%;
      background: white;
      overflow: auto;
      max-height: 60vh;

      h2 {
        margin-top: 0;
      }

      box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    }

    .inline-search-results .result {
      cursor: pointer;
    }
  }
}
