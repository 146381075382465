.logo-container--right {
  display: none;
}

.v-#{$design-version} {
  .reader__header.horizontal-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .reader-tabs,
    .reader-tabs-placeholder {
      width: 100%;
    }

    .logo-container--right {
      display: block;

      .logo {
        display: block;
      }
    }
  }

  .inline-search {
    display: none;
  }

  @include bp-reader-tabs {
    .reader__header.horizontal-header.inline-search-active {
      .reader-tabs,
      .button--toggle-search,
      .button--downloads,
      .button--language {
        display: none !important;
      }

      .inline-search {
        display: block;
      }

      justify-content: space-between;
    }
  }

  .reader__header.horizontal-header {
    .button {
      flex-shrink: 0;
    }

    .button--menu {
      margin-right: auto;

      @include override {
        order: 10;
      }
      @include bp-reader-tabs {
        display: none;
      }
    }

    .button--close {
      @include override {
        order: 20;
        display: flex;
      }

      @include bp-reader-tabs {
        & > p {
          display: none;
        }
      }
    }

    .logo-container {
      @include override {
        order: 30;
      }
    }
    .reader-tabs {
      @include override {
        order: 40;
      }
      @include override-reader-tabs {
        display: inherit;
      }
    }
    .inline-search {
      width: 100%;

      @include override {
        order: 40;
      }
    }
    .button--downloads {
      @include override {
        order: 60;
      }
    }
    .button.button--toggle-search {
      @extend .button--downloads;

      @include override {
        order: 50;
        display: none;
      }

      @include override-reader-tabs {
        display: flex;
      }
    }
    .button--language,
    .language-switcher-container {
      @include override {
        order: 70;
      }
    }
    .logo-container--right {
      @include override {
        padding-right: 24px;
        order: 80;
      }
    }
  }
}
