.italic {
  font-style: italic;
}

.bold,
b,
strong {
  font-weight: $base-typography-bold-font-weight;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

h1 {
  letter-spacing: 0;
}

ul.numeric {
  list-style: decimal;
}
