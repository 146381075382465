@at-root {
  @include drop-down(
    'dropdown',
    $dropdown--background-color,
    $dropdown--border-color,
    $dropdown--link--color,
    $dropdown--link--color-selected,
    $dropdown--link--hover-background-color
  );

  .dropdown--language {
    left: $dropdown--language-left;
    transform: translateX(-50%);
  }
}
