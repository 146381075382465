.reader__header {
  position: fixed;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  border-bottom: $header-border-bottom;
  background: $header-background;
  z-index: $header-z-index;

  height: $header-height;

  .left {
    grid-column: 1;
    height: $header-height;

    .navigateToContentBox {
      &:focus {
        opacity: 1 !important;
      }
    }
  }

  .right {
    grid-column: 2;
    padding-right: 0;

    @include bp(tablet) {
      padding: $header-right--pading;
    }
  }



  &--with-tabs {
    @include bp(desktop) {
      grid-template-columns: auto 1fr auto;

      .left {
        grid-column: 1;
      }

      .reader-tabs {
        grid-column: 2;
      }

      .right {
        grid-column: 3;
      }

      .reader-tabs,
      .reader-tabs__additional-buttons {
        display: flex;
        flex-direction: row;
        justify-content: $reader-tabs-justify-content;
      }
    }
  }

  .left,
  .right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .left {
    justify-content: flex-start;

    @include bp(tablet) {
      width: $navigation--phone-max-width;
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    color: $header--button-color;

    min-width: $header--button-min-width;

    @each $buttonName,
    $padding in $header--button--padding--menu-visible {
      &.button--#{$buttonName} {
        padding: $padding;
      }
    }

    @include bp(tablet) {
      min-width: $header--button--tablet-min-width;

      @each $buttonName,
      $padding in $header--button--padding--menu-visible {
        &.button--#{$buttonName} {
          padding: $padding;
        }
      }
    }

    height: $header--button-height;

    padding-left: $header--button-padding-left;
    padding-right: $header--button-padding-right;

    border-right: $header--button-border-right;

    text-decoration: none;
    cursor: pointer;
    user-select: none;

    .icon-font {
      font-size: 20px;
      width: $header--button--img-width;
      height: $header--button--img-height;
      display: inline-block;
    }

    p {
      // color: $header--button-color;
      margin: $header--button--p-margin;
      font-weight: $header--button--p-font-weight;
      font-size: $header--button--p-font-size;
      line-height: $header--button--p-line-height;
      font-family: $header--button--p--all--buttons-font-family;

      @include bp(all-buttons) {
        font-size: $header--button--p--all--buttons-font-size;
      }

      user-select: none;
    }
  }

  .button--menu,
  .button--close {
    background: $header--button--menuclose-background;
    color: $header--button--menuclose-color;

    @if ($header--button--menuclose--active-class-enabled) {

      &:hover,
      &:active,
      &.active {
        background: $header--button--menuclose--active-background;
      }
    }

    box-shadow: $header-box-shadow;
  }

  @if ($header--button--menuclose--active-class-enabled) {
    .button--search {
      &.active {
        background: $header--button--menuclose--active-background;
      }
    }
  }

  .button--menu {
    margin: $header--button--menu-margin;

    @include bp(bigger-phones) {
      margin: $header--button--menu--bigger-phones-margin;
    }

    .icon-font {
      width: $header--button--menu--img-width;
      height: $header--button--menu--img-height;
      color: $header--button--menu--img-color;

      &::before {
        font-size: $header--button--menu-icon-font-size;
      }
    }

    @if ($header--button--menu--hide-title) {
      align-items: center;
      justify-content: center;

      p {
        display: none !important;
      }
    }
  }

  .button--search,
  .button--language,
  .button--downloads {
    position: relative;
    background: $header--button--delimiter-background;
    background-size: $header--button--delimiter-background-size;

    &:not(.active):hover {
      background-color: $header--button--hover-background-color;
    }

    &:active {
      background-color: $header--button--active-background-color;
    }
  }

  .button--search,
  .button--downloads {
    .icon-font {
      width: $header--button--search--img-width;
      height: $header--button--search--img-height;
      color: $header--button--search--img-color;
    }
  }

  .button--language {
    @include bp(all-buttons) {
      display: flex;
    }

    .icon-font {
      color: $header--button--language--img-color;
      width: $header--button--language--img-width;
      height: $header--button--language--img-height;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .button--close {
    .icon-font {
      width: $header--button--close--img-width;
      height: $header--button--close--img-height;
      color: $header--button--close--img-color;
    }
  }

  .button--downloads {
    .icon-font {
      color: $header--button--downloads--img-color;
    }
  }

  @each $buttonName,
  $showIcon in $header--button--show--label {
    .button--#{$buttonName} {
      @if not $showIcon {
        p {
          display: none !important;
        }
      }
    }
  }

  @if ($header--button--direction-horizontal) {
    .button {
      flex-direction: row;

      p {
        margin-bottom: 0;
        margin-top: 0;
        display: none;

        @include bp(tablet) {
          display: block;
        }
      }
    }
  }

  .logo {
    @if $header--logo--left-enabled {
      display: block;
    }

    @else {
      display: none;
    }

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: $header--logo--phone-width;
    height: $header--logo--phone-height;
    background-image: url('../images/logo-2x.png');

    @include bp(bigger-phones) {
      width: $header--logo--bigger-phones-width;
      height: $header--logo--bigger-phones-height;
    }

    @include bp(all-buttons) {
      width: $header--logo--all-buttons-width;
      height: $header--logo--all-buttons-height;
    }
  }
}

.reader__header.small {
  .left {
    // ie11 fixes the positioning of the whole header ... shrug...
    @include bp-default-sizes(height, $header--small-height, $header-height);
  }

  @include bp-default-sizes(height, $header--small-height, $header-height);

  .button {
    @include bp-default-sizes(height, $header--small-height, $header-height);

    @if $header--small--button-height {
      height: $header--small--button-height !important;
    }
  }

  @media screen and (max-width: 767px) {
    .logo {
      width: $header--logo--phone-width;
      height: $header--logo--phone-height;
    }

    .button {
      padding: $header--small--button-padding;

      p {
        margin-bottom: 0;
        font-size: 12px;
      }
    }

    .button--menu,
    .button--close,
    .button--search {
      .icon-font {
        font-size: 17px;
        width: 17px;
        height: 17px;
      }
    }

    .button--menu {
      @if ($header--button--direction-horizontal) {
        p {
          display: none;
        }

        @include bp(tablet) {
          p {
            display: block;
          }
        }
      }

      @include multi-bp(margin-right,
        (0px: 5px,
          375px: 10px,
          414px: 14px));
    }

    .button--search,
    .button--downloads,
    .button--close,
    .button--language {
      min-width: auto;
      justify-content: center;

      p {
        display: none;
      }

      border-left: $header--button--small-border-left;

      @include bp(tablet) {
        border-left: 0;
      }
    }
  }
}