.reader__body {
  .sidebar {
    // display: grid;
    // grid-template-rows: auto 1fr;
    // grid-template-columns: 1fr;

    overflow: hidden;
    background-color: $sidebar--background-color;
    border-right: $sidebar--border;

    @if $sidebar-padding {
      padding: $sidebar-padding;
    }

    @if $sidebar-padding-big {
      @include bp(tablet) {
        padding: $sidebar-padding-big;
      }
    }

    z-index: 99;

    & > *:nth-child(1) {
      // grid-row: 1;
    }
    & > *:nth-child(2) {
      // grid-row: 2;
      flex: 0 0 auto;
      height: 100%;
    }

    .tabcontrol {
      min-height: 0;
    }
  }
}
