$hint-border-left: 1px solid $blue;
$hint-padding: 0 50px 0 25px;
$hint--box-color: $blue;
$hint--boxtext-color: $blue;
$hint--boxdigit--digit-color: $blue;
$hint--boxdigit--digitvalue-color: $blue;

@mixin generate-hint() {
  .swisscom-hint {
    @extend .hint;
  }

  .swisscom-box {
    @extend .box;
  }

  .swisscom-box-text {
    @extend .box-text;
  }

  .swisscom-box-digit,
  .swisscom-box-digit-null {
    @extend .box-digit;
  }

  .swisscom-hint--special,
  .hint--special {
    display: block;

    height: auto;

    & > div:first-child {
      margin-bottom: 25px;
    }

    & > div:nth-child(2) {
      display: inline-block;
    }

    & > div:last-child {
      display: block;

      .swisscom-box-digit .box-digit {
        & > .digit-value:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
