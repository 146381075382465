.reader__body {
  .overlay {
    &.download-section {
      &--shown {
        display: block;
      }
    }
  }

  .icon-font-navigation-close {
    float: right;
    font-size: $downloads--close--font-size;
    margin-top: $downloads--close--margin-top;
    color: var(--downloads-reports-close-icon-color, $downloads--close--color);
    cursor: pointer;
  }

  .download-section {
    border: none;

    padding: var(--downloads-padding, 32px);

    &::backdrop {
      background-color: var(--downloads-backdrop-color, hsla(0, 0%, 0%, 0.25));
    }

    .title.title--main {
      margin: 0 0 32px 0;
      font-size: 24px;
      line-height: 32px;
      color: var(--downloads-reports-title-main-color, black);
    }

    .reports li {
      background: none;

      &:not(:last-child) {
        border-bottom: var(--downloads-reports-li-border-bottom, none);
      }
    }

    .content {
      width: auto;
      margin: 0 auto;
      @include multi-bp(padding-left, $grid-paddings-widths);
      @include multi-bp(padding-right, $grid-paddings-widths);
      @include bp(1280px) {
        min-width: 600px;
      }
      color: $downloads--color;

      @at-root .navigation-shown#{&} {
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    .order__successful__wrapper {
      padding: 35px 30px 35px 60px;
      margin: 50px 0;

      color: $blue;

      font-weight: 500;

      background-color: $downloads--order-successfull--background-color;
      background-image: url('/dist/2015/images/info-icon-2x.png');
      background-size: 8px 24px;
      background-position: center left 30px;
      background-repeat: no-repeat;
    }

    .report {
      list-style: none;

      margin: var(--downloads-reports-margin, 0 0 12px 0);
      &:last-child {
        margin: var(--downloads-reports-margin-last-child, 0);
      }

      &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: var(--downloads-reports-grid-gap, 16px);
      }

      &__title {
        margin: 0;
        font-size: var(--downloads-report-title-font-size, $downloads--report-title--font-size);
        font-weight: var(--downloads-report-title-font-weight, $downloads--report-title--font-weight);
        line-height: var(--downloads-report-title-line-height, 1);
        color: var(--downloads-reports-title-color, black);
      }

      &__subtitle {
        grid-row: 2;
        color: var(--downloads-reports-subtitle-color, black);
      }

      &__link {
        grid-column: 2;
        grid-row: span 2;
        align-self: center;

        font-size: $downloads--report-link--font-size;
        text-decoration: none;

        &,
        &:active,
        &:visited {
          color: var(--downloads-reports-download-link-color, $downloads--report-link--color);
        }
      }

      &__partials {
        grid-row: 2;
        grid-column: span 2;

        @include multi-bp(margin, $downloads--report-partials--paddings);

        ul {
          margin: 0;
        }
      }

      &__partial {
        display: grid;
        grid-template-columns: 1fr auto;
        padding-left: 0;
      }

      &__order-teaser {
        grid-row: 3;
        grid-column: span 2;

        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: repeat(2, auto);

        background: $downloads--order-teaser--background-color;
        @include multi-bp(padding, $downloads--order-teaser--paddings);

        &-title {
          font-size: 22px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
        }

        &-text {
          grid-row: 2;

          font-size: 16px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          color: $black;
        }

        &-button {
          grid-column: 2;
          grid-row: span 2;
          align-self: center;
        }
      }
    }

    .archive {
      display: grid;
      grid-template-columns: 1fr auto;

      @include multi-bp(
        padding,
        (
          0px: 25px 10px,
          tablet: 20px 56px,
        )
      );
    }

    .button {
      border: 0;
      width: auto;
      height: auto;
      display: inline-block;
      padding: 11px 22px;
      background: $downloads--button--background-color;
      border-radius: 5px;
      color: $downloads--button--color;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      text-align: center;
      text-decoration: none;
      @include multi-bp(
        font-size,
        (
          0px: 16px,
          tablet: 18px,
        )
      );
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        background: $downloads--button--background-color-hover;
      }
    }
  }
}
