*[data-glossary],
*[data-attachment],
*[data-attachment-shortlinkid] {
  position: relative;

  cursor: pointer;
  user-select: text;

  border-bottom: $links--span--glossaryattachment--after-border-bottom;
  display: inline;

  &:before {
    content: ' ';
    display: inline-block;
  }
}

.gb-table {
  span[data-glossary],
  span[data-attachment] {
    font-weight: $links--span--glossaryattachment--gbtable-font-weight;

    user-select: none;
  }

  tr {
    td:first-child span[data-glossary],
    td:first-child span[data-attachment] {
      font-weight: 300;
    }
  }
}

.external-link {
  position: relative;
  cursor: pointer;
  text-decoration: $links--external-link-text-decoration !important;
  color: $links--external-link-text-color;
  font-weight: $links--external-link-text-font-weight;
  font-family: $links--external-link-font-family;
  font-size: $links--external-link-font-size--small;
  padding-bottom: 1px;
  border-bottom: $links--external-link--glossaryattachment--after-border-bottom;

  @include bp($links--external--font-breakpoint) {
    font-size: $links--external-link-font-size;
  }

  &:hover {
    text-decoration: $links--external-link-hover-text-decoration !important;
  }

  @if ($links--external-link-after-element-enabled) {
    &::after {
      content: '\33';
      font-family: $links--external-link-after-font-family;
      display: $links--external-link-after-display;
      margin-left: $links--external-link-after-margin-left;
    }
  }
}

*[data-internallink],
*[data-shortlinkid],
*[data-shortlinkurl],
.internal-link {
  position: relative;

  cursor: pointer;
  user-select: text;

  color: $links--internal-link-text-color;
  font-weight: $links--internal-link-font-weight;
  font-family: $links--internal-link-font-family;
  font-size: $links--internal-link-font-size--small;
  text-decoration: $links--internal-link-text-decoration !important;

  @include bp($links--internal--font-breakpoint) {
    font-size: $links--internal-link-font-size;
  }

  &:hover {
    text-decoration: $links--internal-link-hover-text-decoration !important;
  }

  @if ($links--internal-link-after-element-enabled) {
    &::after {
      content: $links--internal-link-after-content;
      font-family: $links--internal-link-after-font-family;
      display: $links--internal-link-after-display;
      margin-left: $links--internal-link-after-margin-left;
    }
  }
}

@if $links--p-link-spacing-enabled {
  p + a,
  p + *[data-internallink] {
    display: $links--p-link-spacing-display;
    margin-top: $links--p-link-spacing-margin-top;
    margin-bottom: $links--p-link-spacing-margin-bottom;

    @include bp($links--p-link-spacing-breakpoint) {
      margin-top: $links--p-link-spacing-margin-top--bigger;
      margin-bottom: $links--p-link-spacing-margin-bottom--bigger;
    }
  }
}

*[data-videourl] {
  cursor: pointer;
}
