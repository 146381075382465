@mixin table-generate-shadows($parent-class) {
  @media only screen and (max-width: 1024px) {
    #{$parent-class} {
      background:
            /* Shadow covers */ linear-gradient(90deg, white 30%, rgba(white, 0)),
        /* Shadows */ linear-gradient(90deg, rgba(0, 0, 0, 0.1) 30%, rgba(white, 0)),
        linear-gradient(90deg, rgba(white, 0), rgba(0, 0, 0, 0.1) 100%) 100% 0;
      background-repeat: no-repeat;
      background-color: white;
      @include bp-default-sizes(background-size, '40px 100%, 6px 100%, 6px 100%', null, null, '40px 100%, 8px 100%, 8px 100%');

      /* Opera doesn't support this in the shorthand */
      background-attachment: local, scroll, scroll;
      border-radius: 0.1px;
      user-select: none;
    }

    #{$parent-class} > *:first-of-type {
      background: linear-gradient(90deg, rgba(white, 0), white 50%) calc(100% + 2px) 0;
      background-repeat: no-repeat;
      background-color: transparent;
      background-size: 40px 100%;

      /* Opera doesn't support this in the shorthand */
      background-attachment: local;
    }

    #{$parent-class} {
      tr:hover td {
        // disable hover, it's irritaging since you can't do anything with them
        background: $scroller--enable-scroll-shadows-hover-color !important;
      }
    }

    #{$parent-class}:focus {
      outline: none;
    }
  }
}
