.reader__navigation__menu {
  background: $navigation--menu-background;
  box-shadow: $navigation-box-shadow;
  z-index: $navigation-z-index;

  @include bp(tablet) {
    max-width: $navigation--phone-max-width;
  }
}

.reader__navigation__menu a {
  text-decoration: none !important;
}

.no-touchevents .reader__navigation__menu .level:not(.level--1) {
  &:hover {
    background: $navigation--level--hover-background-color;
  }
}

.reader__navigation__menu .level {
  border-right: $navigation--level-border-right;

  @if $navigation--level-font-family {
    font-family: $navigation--level-font-family;
  }

  &,
  &>* {
    min-height: $navigation--level-min-height;
  }

  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  z-index: $navigation--level-z-index;
  background: $navigation--level-background;
  padding: $navigation--level-padding;

  &.selected {
    @if $navigation--level--selected-background-color-fill-enabled {
      background-color: $navigation--level--selected-background-color !important;
    }

    @if $navigation--level--selected-background-color-fill-text-and-arrow {

      .text,
      .arrow {
        background-color: $navigation--level--selected-background-color;
      }
    }

    color: $navigation--level--selected-color;
    font-weight: $navigation--level--selected-font-weight;

    @if $navigation--level--selected-border {
      border-bottom: $navigation--level--selected-border;
    }

    @if $navigation--level--selected-text-border {
      &:not(.level--1) {

        .text,
        .arrow {
          border-bottom: $navigation--level--selected-text-border;
        }
      }
    }
  }

  .text {
    flex: 1 0 0%;
    display: flex;
    flex-direction: column;
    justify-content: $navigation--level--text-justify-content;
    padding: $navigation--level--text-padding;
  }

  .arrow {
    flex: 0 0 $navigation--level--arrow-max-width;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: $navigation--level--arrow-justify-content;

    // Do not delete max-width as IE11 otherwise messes up.
    max-width: $navigation--level--arrow-max-width;
    padding: $navigation--level--arrow-padding;

    width: $navigation--level--arrow-width;

    @if $navigation--level--arrow-font-size {
      .icon-font {
        font-size: $navigation--level--arrow-font-size;
      }
    }
  }

  .color {
    flex: 0 0 $navigation--level--color-width;
    width: $navigation--level--color-width;
  }
}

.no-touchevents .reader__navigation__menu .level--system {
  &:hover {
    background-color: $navigation--level--system-background;
  }
}

.reader__navigation__menu .level--system {
  display: block;

  @include bp(tablet) {
    display: none;
  }

  background: $navigation--level--system-background;
  color: $navigation--level--system-color;
  font-weight: $navigation--level-font-weight;
  border-top: $navigation--level--system-border-top;
  border-bottom: $navigation--level--system-border-bottom;
}

.reader__navigation__menu .level--1,
.reader__navigation__menu .level--2 {
  min-height: $navigation--level--x-min-height;
  max-width: 100%;

  font-size: $navigation--level--x-font-size;
  font-weight: $navigation--level--x-font-weight;

  &.expanded {
    margin-bottom: 0;
    font-weight: $navigation--level--x--expanded-font-weight;

    @if ($navigation--level--x--expanded-enable-different-font-color) {
      color: $navigation--level--x--expanded-font-color !important;
    }
  }

  &.selected {
    font-weight: $navigation--level--x--selected-font-weight;
  }

  &>* {
    min-height: $navigation--level--x-min-height;
  }
}

@if ($navigation--level--all-same-height) {
  .reader__navigation__menu {

    .level--1,
    .level--2,
    .level--3,
    .level--4,
    .level--5 {

      &,
      &>* {
        min-height: $navigation--level--x-min-height;
      }
    }

    @if ($navigation--level--all-same-height-system-too) {

      .level--system,
      .level--system>.text {

        &,
        &>* {
          min-height: $navigation--level--x-min-height;
        }
      }
    }
  }
}

@if ($navigation--level-all-treat-expanded-font-weight-same) {
  .reader__navigation__menu {

    .level--3,
    .level--4,
    .level--5 {
      &.expanded {
        font-weight: bold;
      }
    }
  }
}

@if ($navigation--level--x--expanded-enable-different-font-color) {
  .reader__navigation__menu {

    .level--3,
    .level--4,
    .level--5 {
      &.expanded {
        @if ($navigation--level--x--expanded-enable-different-font-color) {
          color: $navigation--level--x--expanded-font-color !important;
        }
      }
    }
  }
}

@mixin border-left-mixin() {
  transition: $navigation--level-all-border-left-transition;
  border-left: $navigation--level-all-border-left;

  &:hover {
    border-left-color: $navigation--level-all-border-left-hover-color;
  }

  &.selected {
    border-left-color: $navigation--level-all-border-left-selected-color;
  }
}

.reader__navigation__menu {
  @include navigation-top-level-x(1,
    $navigation--level--1-background-color,
    $navigation--level--1-color,
    $navigation--level--x-border-bottom,
    $navigation--level--1--color-background-color );
  @include navigation-level-1(1,
    $navigation--level--1-1-background-color,
    $navigation--level--1-1-color,
    $navigation--level--1-1-color,
    $navigation--level--1-1-selected-color );
  @include navigation-level-1(2,
    $navigation--level--1-2-background-color,
    $navigation--level--1-2-color,
    $navigation--level--1-2-color,
    $navigation--level--1-2-selected-color );

  @include navigation-top-level-x(2,
    $navigation--level--2-background-color,
    $navigation--level--2-color,
    $navigation--level--x-border-bottom,
    $navigation--level--2--color-background-color );

  .report--1 {
    @include navigation-level-2(1, $navigation--level--2-1-color);
    @include navigation-level-2(2, $navigation--level--2-2-color);
    @include navigation-level-2(3, $navigation--level--2-3-color);
    @include navigation-level-2(4, $navigation--level--2-4-color);
    @include navigation-level-2(5, $navigation--level--2-5-color);
    @include navigation-level-2(6, $navigation--level--2-6-color);
  }

  .level--1 {
    @if $navigation--level--1-border-bottom {
      border-bottom: $navigation--level--1-border-bottom;
    }

    @if $navigation--level--1-font-weight {
      .text {
        font-weight: $navigation--level--1-font-weight;
      }
    }

    @if $navigation--level--1-border-left-enabled {
      @include border-left-mixin();
    }

    .text,
    .arrow {
      @if $navigation--level--2-border-bottom {
        border-bottom: $navigation--level--2-border-bottom;
      }

      @else {
        border-bottom: $navigation--level--x-border-bottom;
      }

      @if $navigation--level--1-min-height {

        &,
        &>* {
          min-height: $navigation--level--1-min-height;
        }
      }
    }

    &.expanded {

      .text,
      .arrow {
        @if $navigation--level--1--expanded-border-bottom {
          border-bottom: $navigation--level--1--expanded-border-bottom;
        }
      }

      .text {
        @if $navigation--level--1--expanded-font-weight {
          font-weight: $navigation--level--1--expanded-font-weight;
        }
      }
    }
  }

  .level--2 {

    .text,
    .arrow {
      @if $navigation--level--2-border-bottom {
        border-bottom: $navigation--level--2-border-bottom;
      }

      @else {
        border-bottom: $navigation--level--x-border-bottom;
      }
    }

    @if $navigation--level--2-font-size {
      font-size: $navigation--level--2-font-size;
    }

    @if $navigation--level--2-border-left-enabled {
      @include border-left-mixin();
    }

    @if $navigation--level--2-min-height {

      &,
      &>* {
        min-height: $navigation--level--2-min-height;
      }
    }

    &.expanded {

      .text,
      .arrow {
        @if $navigation--level--2--expanded-border-bottom {
          border-bottom: $navigation--level--2--expanded-border-bottom;
        }
      }

      .text {
        @if $navigation--level--2--expanded-font-weight {
          font-weight: $navigation--level--2--expanded-font-weight;
        }
      }
    }
  }

  .level--3 {
    color: $navigation--level--3-color;
    font-size: $navigation--level--3-font-size;
    background: $navigation--level--3-background;

    @if $navigation--level--3-border-left-enabled {
      @include border-left-mixin();
    }

    @if $navigation--level--3-font-size {
      font-size: $navigation--level--3-font-size;
    }

    @if $navigation--level--3-min-height {

      &,
      &>* {
        min-height: $navigation--level--3-min-height;
      }
    }

    &.expanded {
      background: $navigation--level--3--section-expanded-background;

      .text,
      .arrow {
        @if $navigation--level--3--expanded-border-bottom {
          border-bottom: $navigation--level--3--expanded-border-bottom;
        }
      }

      .text {
        @if $navigation--level--3--expanded-font-weight {
          font-weight: $navigation--level--3--expanded-font-weight;
        }
      }
    }

    .text {
      padding-left: $navigation--level--3--text-padding-left;

      @if $navigation--level--3-border-bottom {
        border-bottom: $navigation--level--3-border-bottom;
      }

      @else {
        border-bottom: $navigation--level--x-border-bottom;
      }
    }

    .arrow {
      @if $navigation--level--3-border-bottom {
        border-bottom: $navigation--level--3-border-bottom;
      }

      @else {
        border-bottom: $navigation--level--x-border-bottom;
      }

      .arrow__down,
      .arrow__up {
        transform: scale(0.6);
      }

      color: $navigation--level--1-color;
      font-size: $navigation--level--3--arrow-font-size;
    }

    .color {
      background: $navigation--level--3--color-background-color;
    }
  }

  .level--4 {
    color: $navigation--level--4-color;
    font-size: $navigation--level--4-font-size;
    background: $navigation--level--4-background;

    @if $navigation--level--4-border-left-enabled {
      @include border-left-mixin();
    }

    @if $navigation--level--4-font-size {
      font-size: $navigation--level--4-font-size;
    }

    @if $navigation--level--4-min-height {

      &,
      &>* {
        min-height: $navigation--level--4-min-height;
      }
    }

    .text {
      padding-left: $navigation--level--4--text-padding-left;

      @if $navigation--level--4-border-bottom {
        border-bottom: $navigation--level--4-border-bottom;
      }

      @else {
        border-bottom: $navigation--level--x-border-bottom;
      }
    }

    .arrow {
      @if $navigation--level--4-border-bottom {
        border-bottom: $navigation--level--4-border-bottom;
      }

      @else {
        border-bottom: $navigation--level--x-border-bottom;
      }

      .arrow__down,
      .arrow__up {
        transform: scale(0.6);
      }

      color: $navigation--level--4-color;
      font-size: $navigation--level--4--arrow-font-size;
    }

    &.expanded {

      .text,
      .arrow {
        @if $navigation--level--4--expanded-border-bottom {
          border-bottom: $navigation--level--4--expanded-border-bottom;
        }
      }

      .text {
        @if $navigation--level--4--expanded-font-weight {
          font-weight: $navigation--level--4--expanded-font-weight;
        }
      }
    }
  }

  .level--5 {
    color: $navigation--level--5-color;
    font-size: $navigation--level--5-font-size;
    background: $navigation--level--5-background;

    margin: $navigation--level--5-margin;
    border-top: $navigation--level--5-border-top;

    @if $navigation--level--5-border-left-enabled {
      @include border-left-mixin();
    }

    @if $navigation--level--5-font-size {
      font-size: $navigation--level--5-font-size;
    }

    @if $navigation--level--5-min-height {

      &,
      &>* {
        min-height: $navigation--level--5-min-height;
      }
    }

    .text {
      padding-left: $navigation--level--5--text-padding-left;

      @if $navigation--level--5-border-bottom {
        border-bottom: $navigation--level--5-border-bottom;
      }
    }

    .arrow {
      @if $navigation--level--5-border-bottom {
        border-bottom: $navigation--level--5-border-bottom;
      }

      .arrow__down,
      .arrow__up {
        transform: scale(0.6);
      }

      color: $navigation--level--5-color;
      font-size: $navigation--level--5--arrow-font-size;
    }

    &.expanded {

      .text,
      .arrow {
        @if $navigation--level--5--expanded-border-bottom {
          border-bottom: $navigation--level--5--expanded-border-bottom;
        }
      }

      .text {
        @if $navigation--level--5--expanded-font-weight {
          font-weight: $navigation--level--5--expanded-font-weight;
        }
      }
    }
  }

  .submenu {
    display: none;
    background: $navigation--level-background;

    &.expanded {
      display: block;
      background: $navigation--submenu-x-expanded-background;

      & :not(.level--1):not(.level--2).selected {
        @if $navigation--submenu-x-expanded-background-selected-background-enabled {
          background: $navigation--submenu-x-expanded-background-selected-background;
        }

        @if $navigation--level--selected-background-color-fill-text-and-arrow {

          .text,
          .arrow {
            background: $navigation--submenu-x-expanded-background-selected-background;
          }
        }
      }
    }
  }

  .submenu--2 {
    padding: $navigation--submenu--2-padding;
  }

  .submenu--3 {
    padding: $navigation--submenu--3-padding;

    &.expanded {
      background: $navigation--submenu--3--expanded-background;
    }
  }
}

@if ($navigation-colors-enabled ==false) {
  .reader__navigation__menu {
    .color {
      display: none;
    }
  }
}

.level {
  @if $navigation--level-all-arrow-color {
    .arrow {

      &,
      span {
        color: $navigation--level-all-arrow-color !important;
      }
    }
  }

  @if $navigation--level-all-arrow--expanded-color {
    &.expanded .arrow {

      &,
      span {
        color: $navigation--level-all-arrow--expanded-color !important;
      }
    }
  }
}