.reader-tabs {
  display: none;
  align-self: $reader-tabs-align-self;
  overflow: visible;
  padding-left: $reader-tabs--padding-left;
  margin-left: $reader-tabs--margin-left;
  margin-bottom: $reader-tabs--margin-bottom;

  &__wrapper {
    display: inline-flex;
    flex-direction: row;
  }

  &__additional-buttons {
    display: none;
    flex-direction: row;
    align-items: flex-end;
    align-self: $reader-tabs-align-self;
  }

  &__init-render {
    visibility: hidden;
    position: fixed;
    top: -2000px;
    display: flex;
  }

  &__reports {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__tab {
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: $reader-tabs--tab--max-width;
    height: $reader-tabs--tab--height;

    background-color: $reader-tabs--tab--background-color;
    padding: $reader-tabs--tab--padding;
    border: $reader-tabs--border-width solid $reader-tabs--border-color;
    border-bottom: $reader-tabs--border-bottom-width solid $reader-tabs--border-bottom-color;
    border-width: $reader-tabs--border-width 0 $reader-tabs--border-width $reader-tabs--border-width;

    cursor: pointer;
    text-decoration: none;

    font-family: $reader-tabs--tab--font-family;
    font-size: $reader-tabs--tab--font-size;
    font-weight: $reader-tabs--tab--font-weight;
    line-height: $reader-tabs--tab--line-height;
    color: $reader-tabs--tab--color;

    &:hover {
      text-decoration: none;
      color: $reader-tabs--tab--color;
    }

    & > .icon-font-content-close {
      display: flex;
      align-items: center;

      height: 100%;
      margin-right: $reader-tabs--tab--close--margin-right;
      padding: $reader-tabs--tab--close--padding;
      font-size: $reader-tabs--tab--close--font-size;
    }

    & > span:first-of-type {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:nth-child(n + 2) {
      margin-left: $reader-tabs--tab-margin-left;
    }

    &:last-child {
      border-right-width: $reader-tabs--border-width;
    }

    &:hover {
      background-color: $reader-tabs--tab--background-color-hover;
    }

    &.selected {
      color: $reader-tabs--tab--selected-color;
      background-color: $reader-tabs--tab--background-color-selected;
      border-bottom: transparent;
      border-bottom: $reader-tabs--selected-border-bottom-width solid $reader-tabs--selected-border-bottom-color;
      @if ($reader-tabs--tab--selected-border) {
        border: $reader-tabs--tab--selected-border;
      }
    }
  }

  .reader-tabs__additional-buttons {
    margin: $reader-tabs--additional-buttons--margin;
    .reader-tabs__tab {
      color: $reader-tabs--additional-buttons--tab--color;
      font-weight: $reader-tabs--additional-buttons--tab--font-weight;
    }

    .dropdown--show-all-tabs {
      top: 100%;
      left: 50%;
      padding: 0;

      width: 300px;
      margin-top: 18px;
      margin-left: 0;

      transform: translateX(-50%);

      a {
        white-space: nowrap;
        text-overflow: ellipsis;
        justify-content: space-between;
        max-width: 100%;
        width: 100%;
      }
    }

    .show-all-button {
      position: relative;
      color: $reader-tabs--additional-buttons--add-new-color;
    }

    .add-new-button {
      min-width: $reader-tabs--additional-buttons--add-new-min-width;
      background-color: $reader-tabs--additional-buttons--add-new-background-color;
      border: $reader-tabs--additional-buttons--add-new-border;
      border-bottom: $reader-tabs--additional-buttons--add-new-border-bottom;
      color: $reader-tabs--additional-buttons--add-new-color;

      .icon-font {
        display: flex;
        justify-content: center;
        align-items: center;

        color: $reader-tabs--additional-buttons--add-new-color;
        width: $reader-tabs--additional-buttons--add-new-width;
        height: $reader-tabs--additional-buttons--add-new-height;
        margin: $reader-tabs--additional-buttons--add-new-margin;

        font-size: $reader-tabs--additional-buttons--add-new-width;
      }
    }
  }
}
