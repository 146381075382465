@if ($reader--tools-enabled) {
  *[data-reader-social-media-enabled] {
    position: relative;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &::before {
      text-align: center;
      cursor: pointer;
      content: $icon-font-share / "";
      font-family: '#{$icon-font}';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      margin-left: -#{$reader--tools-width + $reader--tools--share--button-right-spacer};
      font-size: $reader--tools--share--button-font-size;
      width: $reader--tools-width;
      color: $reader--tools--share--button-color;
      @if $reader--tools--share--button-right-spacer {
        font-weight: $reader--tools--share--button-font-weight;
      }
    }
  }

  .webui-popover {
    z-index: 95;
    box-shadow: $reader--tools--share--box-shadow;
    border: $reader--tools--share-border;
    @if $reader--tools--share-border-top {
      border-top: $reader--tools--share-border-top;
    }
    padding: $reader--tools--share-padding;
    background-color: 0;
    background: $reader--tools--share-background;
    border-radius: $reader--tools--share-border-radius;

    @if ($reader--tools--share-border-width) {
      border-width: $reader--tools--share-border-width;
    }

    // these three properties are only used because the calculation of the popover is wrong in case
    margin-top: 88px;
    margin-left: $reader--tools--share-width - $reader--tools-width + 8px !important;
    width: $reader--tools--share-width !important;

    .webui-arrow {
      display: none;
    }

    a::after {
      display: none !important;
    }

    .webui-popover-title,
    .webui-popover-content {
      background: $reader--tools--share-background;
      color: $reader--tools--share-font-color;
      border: 0;
    }

    .webui-popover-title {
      font-size: $reader--tools--share-font-size;
      font-weight: $reader--tools--share-font-weight;
      @if $reader--tools--share-font-font-family {
        font-family: $reader--tools--share-font-font-family;
      }
      @if $reader--tools--share-font-text-align {
        text-align: $reader--tools--share-font-text-align;
      }
      @if $reader--tools--share-font-line-height {
        line-height: $reader--tools--share-font-line-height;
      }
      @if $reader--tools--share-font-text-transform {
        text-transform: $reader--tools--share-font-text-transform;
      }
      @if $reader--tools--share-font-margin {
        margin: $reader--tools--share-font-margin;
      }
    }

    .webui-popover-content {
      .reader-sharing-tools {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .social-media-sharing {
          outline: none;
          text-decoration: none;
          display: block;
          height: $reader--tools--share--icon-height;
          width: $reader--tools--share--icon-height;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;

          border: $reader--tools--share--icon-border;
          border-radius: $reader--tools--share--icon-border-radius;

          transition: all 0.25s ease-out;

          &:hover {
            background: $reader--tools--share--icon--hover-background;
            border-color: $reader--tools--share--icon--hover-border;
            & > i {
              color: $reader--tools--share--icon--hover-color;
            }
          }

          &:active {
            border-color: $reader--tools--share--icon--active-border;
            & > i {
              color: $reader--tools--share--icon--active-border;
            }
          }

          &:not(:last-of-type) {
            margin: $reader--tools--share-margin;
          }

          & > i {
            font-size: $reader--tools--share--icon-size;
            color: $reader--tools--share--icon-color;

            &.icon-font-linkedin {
              margin: -2px 0 0 2px;
            }

            &.icon-font-facebook {
              margin: 0 0 0 -2px;
            }

            &.icon-font-twitter {
              margin: 2px 0 0 2px;
            }
          }
        }
      }
    }
  }
}
