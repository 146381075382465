.report-switcher {
  // reset
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      color: $report-switcher--li--color;
      padding: 0;
      margin: 0;
      background: none;
    }
  }

  &__hint-text {
    padding: var(--report-switcher-hint-text-padding, 8px 10px);
    color: var(--report-switcher-hint-text-color, black);
  }

  .badge {
    margin: $report-switcher--badge--margin;
    padding: $report-switcher--badge--padding;
    border-radius: $report-switcher--badge--border-radius;
    font-weight: $report-switcher--badge--font-weight;
    font-size: $report-switcher--badge--font-size;
    color: $report-switcher--badge--color;

    &--violet {
      background-color: $report-switcher--badge--background-color;
    }
  }

  &__input {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 300px;
    margin: $report-switcher--input-margin;
    padding: $report-switcher--input--padding;
    background: $report-switcher--input--background;
    border: $report-switcher--input--border;
    background: $reportswitcher-background;
    color: $reportswitcher-color;
    border: $reportswitcher-border;
    height: $reportswitcher-height;
    font-weight: $reportswitcher-font-weight;
    font-size: $reportswitcher-font-size;
    font-family: inherit;
    outline: $reportswitcher-outline;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: $reportswitcher-padding;
    border-radius: $reportswitcher-radius;

    &::-ms-expand {
      display: none;
    }

    @include bp(bigger-phones) {
      max-width: 400px;
    }

    @include bp(tablet) {
      max-width: 300px;
    }

    @include bp(desktop) {
      display: none;
      margin-left: 10px;
      margin-right: 10px;
      width: auto;
    }

    &--show {
      @include bp(desktop) {
        display: flex;
      }
    }
  }

  &__popup-backdrop {
    display: none;
    width: 100%;
    position: fixed;
    top: $header-height;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $report-switcher--backdrop--background-color;
    z-index: 1000;
  }

  &__popup {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 300px;
    background: $report-switcher--popup--background;
    @include multi-bp(padding, $report-switcher--popup--padding);
    z-index: 1001;

    grid-template-rows: auto 1fr auto;
    & > :nth-child(3) {
      align-self: flex-end;
    }

    @include bp(tablet) {
      width: 100%;
      min-width: $report-switcher--popup--min-width;
      max-width: $report-switcher--popup--max-width;
    }
  }

  &--open {
    .report-switcher__popup,
    .report-switcher__popup-backdrop {
      display: grid;
    }
  }

  &__popup-close {
    position: absolute;
    top: $report-switcher--popup--close-button--margin;
    right: $report-switcher--popup--close-button--margin;
    cursor: pointer;
    color: $report-switcher--popup--close-button--color;
    font-size: $report-switcher--popup--close-button--font-size;
  }

  .report-list {
    max-height: 45vh;
    overflow: auto;

    li {
      .entry {
        display: block;
        color: $report-switcher--popup--report-list--link--color;
        text-decoration: none;
        padding: $report-switcher--popup--report-list--link--padding;
        font-family: $report-switcher--popup--report-list--link--font-family;

        &.entry-different-languages {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      }

      .languages {
        margin: $report-switcher--popup--report-list--languages--margin;
      }

      .language-entry {
        color: $report-switcher--popup--report-list--link-language--color;
        text-decoration: none;
        padding: $report-switcher--popup--report-list--link-language--padding;
        font-weight: $report-switcher--popup--report-list--link-font-weight;
      }

      &:hover {
        background-color: $report-switcher--popup--report-list--li--background-color-hover;
      }

      &.collapsable {
        &:hover {
          background-color: transparent;
        }

        & > .collapsable__title:hover {
          background-color: $report-switcher--popup--report-list--li--background-color-hover;
        }
      }
    }

    & > li {
      font-size: $report-switcher--popup--report-list--top-level--font-size;
      border-bottom: $report-switcher--popup--report-list--top-level--border-bottom;

      & > a {
        color: $report-switcher--popup--report-list--top-level--color;
      }
    }
  }

  .collapsable {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $report-switcher--popup--collapsable--title--padding;
      border-bottom: $report-switcher--popup--collapsable--title-border-bottom;
      font-weight: $report-switcher--popup--collapsable--title-font-weight;
      font-family: $report-switcher--popup--collapsable--title-font-family;

      .icon-font {
        color: $report-switcher--popup--collapsable--icon-font--color;
      }
    }

    & > ul {
      display: none;
      border-top: $report-switcher--popup--collapsable--ul--border-top;
      margin: $report-switcher--popup--collapsable--ul--margin;
    }

    & li {
      border-bottom: $report-switcher--popup--collapsable--li--border-bottom;
      font-size: $report-switcher--popup--collapsable--li--font-size;
      font-weight: $report-switcher--popup--collapsable--li--font-weight;
      line-height: $report-switcher--popup--collapsable--li--line-height;
      color: $report-switcher--popup--collapsable--li--color;
      padding: $report-switcher--popup--collapsable--li--padding;

      &:last-child {
        border-bottom: none;
      }
    }

    &--open {
      & > .collapsable__title > .icon-font-expand {
        transform: rotate(180deg);
      }

      & > ul {
        display: block !important;
      }
    }

    .collapsable {
      padding-left: $report-switcher--popup--collapsable--collapsable--padding-left;
      padding-right: $report-switcher--popup--collapsable--collapsable--padding-right;

      & > .collapsable__title {
        padding-left: $report-switcher--popup--collapsable--collapsable--title--padding-left;
        padding-right: $report-switcher--popup--collapsable--collapsable--title--padding-right;
      }

      li a {
        padding-left: $report-switcher--popup--collapsable--collapsable--li--link--padding-left;
        padding-right: $report-switcher--popup--collapsable--collapsable--li--link--padding-right;
        font-weight: $report-switcher--popup--collapsable--collapsable--li--link--font-weight;
      }
    }
  }
}
