@use "sass:math";

// create helper classes for bottom / top margins
@for $i from $helper--classes--margin-bottom--top-lower-limit through $helper--classes--margin-bottom--top-upper-limit {
  .reader-margin-bottom-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .reader-margin-top-#{$i} {
    margin-top: #{$i}px !important;
  }
}

@for $i from 0 through 20 {
  $value: math.div($i ,10);
  $classValue: str-replace(inspect($value), '.', '-');

  .reader-padding-#{$classValue} {
    padding: #{$value * 10}px !important;
  }
  .reader-padding-left-#{$classValue} {
    padding-left: #{$value * 10}px !important;
  }
  .reader-padding-right-#{$classValue} {
    padding-right: #{$value * 10}px !important;
  }
  .reader-padding-top-#{$classValue} {
    padding-top: #{$value * 10}px !important;
  }
  .reader-padding-bottom-#{$classValue} {
    padding-bottom: #{$value * 10}px !important;
  }

  .reader-margin-#{$classValue} {
    margin: #{$value * 10}px !important;
  }
  .reader-margin-left-#{$classValue} {
    margin-left: #{$value * 10}px !important;
  }
  .reader-margin-right-#{$classValue} {
    margin-right: #{$value * 10}px !important;
  }
  .reader-margin-top-#{$classValue} {
    margin-top: #{$value * 10}px !important;
  }
  .reader-margin-bottom-#{$classValue} {
    margin-bottom: #{$value * 10}px !important;
  }
}
