@mixin navigation-top-level-x($level, $background-color, $color, $border-bottom, $color-background-color) {
  .level--#{$level} {
    background-color: $background-color;
    color: $color;

    .text {
      border-bottom: $border-bottom;
    }

    .color {
      background-color: $color-background-color;
      border-bottom: $border-bottom;
    }

    .arrow {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: $navigation--level--1--arrow-font-size;
      border-bottom: $border-bottom;
      color: $color;
    }
  }
}

@mixin navigation-level-1($count, $background-color, $text-color, $side-color, $selected-color) {
  .level--1-#{$count} {
    background-color: $background-color;
    color: $text-color;

    .color {
      background-color: $background-color;
    }

    .arrow {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: $navigation--level--1--arrow-font-size;
      color: $side-color;
    }

    &:hover {
      background-color: $background-color;
    }

    &.selected {
      background-color: $selected-color;
      color: $text-color;

      @if $navigation--level--selected-background-color-fill-text-and-arrow {
        .text,
        .arrow {
          background-color: $selected-color;
        }
      }
    }

    @if $navigation--level--1--expanded-background-color {
      &.expanded {
        background-color: $navigation--level--1--expanded-background-color;
      }
    }
  }
}

@mixin navigation-level-2($count, $color) {
  .level--2-#{$count} {
    .color {
      background-color: $color;
    }

    .arrow {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: $navigation--level--2--arrow-font-size;
      color: $color;
    }

    &.expanded {
      background-color: $navigation--level--2--expanded-background-color;

      .color {
        border-bottom: 1px solid $color;
      }
    }
  }

  .submenu--2-#{$count} {
    .color {
      background-color: $color !important;
    }
  }
}
