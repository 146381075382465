$g-base-font-family: 'TheSans', Arial, sans-serif;

$g-base-font-size: 16px;

$g-base-font-weight: 400;
$g-base-font-weight-semi-bold: 600;
$g-base-font-weight-bold: 700;

$typographies: (
  heading1: (
    (18px, 24px),
    $g-base-font-family,
    $g-base-font-weight,
    (1.33em, 1.33em),
    $grey
  ),
  heading2: (
    (32px, 54px),
    $g-base-font-family,
    $g-base-font-weight-bold,
    (1.19em, 1.19em),
    $grey
  ),
  heading3: (
    (16px, 28px),
    $g-base-font-family,
    $g-base-font-weight-bold,
    (1.2em, 1.2em),
    $blue-accessible
  ),
  heading4: (
    (16px, 24px),
    $g-base-font-family,
    $g-base-font-weight,
    (1.2em, 1.2em),
    $blue-accessible
  ),
  heading5: (
    (16px, 21px),
    $g-base-font-family,
    $g-base-font-weight-bold,
    (1.2em, 1.2em),
    $grey
  ),
  heading6: (
    (16px, 21px),
    $g-base-font-family,
    $g-base-font-weight,
    (1.2em, 1.2em),
    $grey
  ),
  paragraph: (
    (16px, 18px),
    $g-base-font-family,
    $g-base-font-weight,
    (1.39em, 1.39em),
    $grey
  ),
  lead: (
    (16px, 24px),
    $g-base-font-family,
    $g-base-font-weight,
    (1.25em, 1.25em),
    $grey
  ),
  link: (
    (15px, 21px),
    $g-base-font-family,
    $g-base-font-weight-semi-bold,
    (1.48em, 1.48em),
    $blue-accessible
  )
);

@mixin typography-generator($element_style_key) {
  @if (map-has-key($typographies, $element_style_key)) {
    $styles: map-get($typographies, $element_style_key);
    @include fmap(font-size, nth($styles, 1));
    font-family: nth($styles, 2);
    font-weight: nth($styles, 3);
    @include fmap(line-height, nth($styles, 4));
    color: nth($styles, 5);
  } @else {
    $valid_keys: map-keys($styles);
    @error 'Unknown element style key #{$element_style_key}. Valid styles: #{$valid_keys}';
  }
}

/**
  Generates base abstract style classes that can be extended.
  */
@mixin abstract-typography-generator() {
  @each $style in map-keys($typographies) {
    %#{$style},
    .#{$style} {
      @include typography-generator($style);
    }
  }
}

@mixin generate-custom-font() {
  @include abstract_typography_generator();

  .bold {
    font-weight: bold !important;
  }

  a {
    font-family: $g-base-font-family;
  }

  @at-root .reader__body {
    font-family: $g-base-font-family;

    h1 {
      @extend %heading1;
      letter-spacing: -0.3px;
    }

    h2 {
      @extend %heading2;
      letter-spacing: -1.4px;
    }
    h3 {
      @extend %heading3;
      letter-spacing: -0.2px;
    }
    h4 {
      @extend %heading4;
      margin-bottom: 16px !important;
    }
    h5 {
      @extend %heading5;
      margin-bottom: 16px !important;
    }
    h6 {
      @extend %heading6;
      margin-bottom: 16px !important;
    }
    .swisscom-body {
      @extend %paragraph;
      @include f((margin-bottom), 24px, 32px);
    }
    .swisscom-lead {
      @extend %lead;
      letter-spacing: -0.2px;

      margin-left: 0;
      padding-left: 0;
      border-left: 0;
    }
    p {
      @extend %paragraph;
    }
    ul li {
      @extend %paragraph;
    }
    .link {
      @extend %link;
    }

    h4 + h5 {
      margin-top: 16px !important;
      @include bp(768px) {
        margin-top: 24px !important;
      }
      @include bp(1280px) {
        margin-top: 32px !important;
      }
    }
  }
}
