html,
body {
  min-height: 100%;
}

@if $base--readerbody--html-reset-active {
  html {
    box-sizing: border-box;
    font-size: 62.5%;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  position: relative;

  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;

  overflow: hidden;
}

@include bp(tablet) {
  body {
    position: relative;

    &.no-scroll-mobile {
      overflow: auto;
    }
  }
}

// -------------------------------------------
// -- Base (p, ul, ...)
// -------------------------------------------

.no-bottom-margin {
  margin-bottom: 0 !important;
}

@if $base--readerbody--p-ul-reset-active {
  .reader__body {
    word-wrap: break-word;

    p {
      margin-top: 0;
      margin-bottom: $base--readerbody--p-margin-bottom;
    }

    ul {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: $base--readerbody--ul-margin-bottom;

      li {
        padding-left: 20px;
        margin-bottom: 5px;

        list-style: $base--readerbody--ul--li-list-style;

        background: $base--readerbody--ul--li-background;
        background-size: 10px 10px;
      }
    }

    .blue {
      color: $base--readerbody--blue-color;
    }
  }
}
