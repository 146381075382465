@use "sass:math";

@mixin grid-include-value-map($bp-name, $map-to-include, $property) {
  @if (map-has-key($map-to-include, $bp-name)) {
    $value: map-get($map-to-include, $bp-name);
    #{$property}: $value;
  }
}

@mixin grid-generate-classes($amount, $class, $property, $suffix: '') {
  @for $i from 1 through $amount {
    #{$class}_#{$i}#{$suffix} {
      #{$property}: $i;
    }
  }
}

@mixin grid-generate-start($amount, $class, $suffix: '') {
  @for $i from 1 through $grid-columns {
    @if ($grid-columns % $i == 0) {
      #{$class}_1_#{$i}#{$suffix} {
        grid-column-end: span #{math.div($grid-columns, $i)};
      }
    }
    #{$class}_#{$i}#{$suffix} {
      grid-column-end: span #{$i};
    }
  }
}
