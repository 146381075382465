@import '../1.0/config/modules/components/dropdown';

@mixin drop-down($name, $background-color, $border-color, $link-color, $link-color-selected, $link-hover-background-color) {
  .#{$name} {
    display: none;
    margin: $dropdown-margin;
    padding: $dropdown-padding;
    position: absolute;
    width: $dropdown-width;
    z-index: 100000;

    left: 0;
    right: 0;
    top: $dropdown-top;

    border-radius: $dropdown-border-radius;
    border: 1px solid $border-color;
    border-width: $dropdown-border-width;

    @if ($dropdown--border-top) {
      border-top: $dropdown--border-top;
    }

    background-color: $background-color;
    box-shadow: $dropdown-box-shadow;

    & > a:first-child {
      border-radius: $dropdown-border-radius !important;
    }
    & > a:not(:last-child) {
      border-bottom: $dropdown-item-separator-border-bottom !important;
    }

    a {
      display: block !important;
      &,
      &:hover {
        text-decoration: none !important;
      }

      padding: $dropdown--a-padding !important;

      color: $link-color !important;

      text-align: $dropdown--a-text-align !important;
      text-decoration: none !important;
      font-weight: $dropdown--a-font-weight !important;

      @if ($dropdown--a-font-size) {
        font-size: $dropdown--a-font-size !important;
      }

      @if ($dropdown--a-font-family) {
        font-family: $dropdown--a-font-family !important;
      }

      &:hover {
        background-color: $link-hover-background-color !important;
        color: $dropdown--link--hover-color !important;
      }

      &.selected {
        display: relative;
        color: $dropdown--a--selected-color !important;
        font-weight: $dropdown--a--selected-font-weight !important;
        background: $dropdown--a--selected-background !important;
      }
    }

    &.show {
      display: block;
    }

    @if ($dropdown--show-arrow) {
      &:after,
      &:before {
        position: absolute;

        content: ' ';
        line-height: 15px;

        bottom: 100%;
        left: 50%;

        width: 0;
        height: 0;

        border: solid transparent;

        pointer-events: none;
      }

      &:after {
        border-bottom-color: $background-color;
        border-width: 16px;
        margin-left: -16px;
      }

      &:before {
        border-bottom-color: $border-color;
        border-width: 18px;
        margin-left: -18px;
      }
    }
  }
}
