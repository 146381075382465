.v-#{$design-version} {
  .horizontal-navigation {
    margin: 0 15px;

    display: none;
    @include bp-reader-tabs {
      display: block;
    }

    .horizontal-menu-wrapper {
      margin: 0 auto;
    }

    .horizontal-menu-level-1 {
      overflow: auto;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .horizontal-menu-level-2 {
      position: fixed;
      width: 100%;
      z-index: 92;
    }

    .horizontal-menu-level-1 .horizontal-menu-toplevel-item {
      margin: 0 30px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    .horizontal-menu-level-2 .horizontal-menu-secondlevel-item {
      margin: 0 30px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }

    .horizontal-menu-toplevel-item,
    .horizontal-menu-secondlevel-item {
      flex: 0 0 auto;
      display: block;
    }

    /*
 * Container style
 */
    .ps {
      overflow: hidden !important;
      overflow-anchor: none;
      -ms-overflow-style: none;
      touch-action: auto;
      -ms-touch-action: auto;
    }

    /*
* Scrollbar rail styles
*/
    .ps__rail-x {
      display: none;
      opacity: 0;
      transition: background-color 0.2s linear, opacity 0.2s linear;
      -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
      height: 10px;
      /* there must be 'bottom' or 'top' for ps__rail-x */
      bottom: 0px;
      /* please don't change 'position' */
      position: absolute;
    }

    .ps__rail-y {
      display: none;
      opacity: 0;
      transition: background-color 0.2s linear, opacity 0.2s linear;
      -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
      width: 10px;
      /* there must be 'right' or 'left' for ps__rail-y */
      right: 0;
      /* please don't change 'position' */
      position: absolute;
    }

    .ps--active-x > .ps__rail-x,
    .ps--active-y > .ps__rail-y {
      display: block;
      background-color: transparent;
    }

    .ps:hover > .ps__rail-x,
    .ps:hover > .ps__rail-y,
    .ps--focus > .ps__rail-x,
    .ps--focus > .ps__rail-y,
    .ps--scrolling-x > .ps__rail-x,
    .ps--scrolling-y > .ps__rail-y {
      opacity: 1;
    }

    .ps .ps__rail-x:hover,
    .ps .ps__rail-y:hover,
    .ps .ps__rail-x:focus,
    .ps .ps__rail-y:focus,
    .ps .ps__rail-x.ps--clicking,
    .ps .ps__rail-y.ps--clicking {
      background-color: transparent;
      opacity: 1;
    }

    /*
* Scrollbar thumb styles
*/
    .ps__thumb-x {
      background-color: #aaa;
      border-radius: 0;
      transition: background-color 0.2s linear, height 0.2s ease-in-out;
      -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
      height: 5px;
      /* there must be 'bottom' for ps__thumb-x */
      bottom: 0px;
      /* please don't change 'position' */
      position: absolute;
    }

    .ps__thumb-y {
      background-color: #aaa;
      border-radius: 0;
      transition: background-color 0.2s linear, width 0.2s ease-in-out;
      -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
      width: 6px;
      /* there must be 'right' for ps__thumb-y */
      right: 0px;
      /* please don't change 'position' */
      position: absolute;
    }

    .ps__rail-x:hover > .ps__thumb-x,
    .ps__rail-x:focus > .ps__thumb-x,
    .ps__rail-x.ps--clicking .ps__thumb-x {
      background-color: #d6d6d6;
      height: 10px;
    }

    .ps__rail-y:hover > .ps__thumb-y,
    .ps__rail-y:focus > .ps__thumb-y,
    .ps__rail-y.ps--clicking .ps__thumb-y {
      background-color: #d6d6d6;
      width: 10px;
    }

    /* MS supports */
    @supports (-ms-overflow-style: none) {
      .ps {
        overflow: auto !important;
      }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .ps {
        overflow: auto !important;
      }
    }
    .scrollbar-container {
      position: relative;
      height: 100%;
    }

    /*# sourceMappingURL=styles.css.map*/
  }
}
