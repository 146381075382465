.tabcontrol {
  display: flex;
  flex-direction: column;
  background-color: $tabs--background-color;

  &__header {
    display: flex;
    flex-direction: row;
    margin-left: 0;
    padding-left: 0;

    flex: 0 0 auto;

    list-style: none;
    margin-bottom: 0 !important;

    background-color: $tabs--header-background-color;

    @if $tabs--header-border-bottom {
      border-bottom: $tabs--header-border-bottom;
    }

    @if $tabs--tab--first--margin-left {
      & > .tab:first-child {
        margin-left: $tabs--tab--first--margin-left;
      }
    }

    & > * {
      @if ($tabs--tab-fill) {
        flex: 1;
      }
      else {
        flex: 0 0 auto;
      }
    }
  }

  &__content {
    flex: 1 1 auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;

    padding-top: $tabs--content--padding-top;
    background-color: $tabs--content--background-color;
    @if ($tabs--content-border-right) {
      border-right: $tabs--content-border-right;
    }
  }

  .tab {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;

    @if $tabs--tab--font-family {
      font-family: $tabs--tab--font-family;
    }

    border: $tabs--tab--border-width solid transparent;
    border-bottom: $tabs--tab--border;
    border-left: 0;

    padding: $tabs--tab--padding;
    margin-top: $tabs--tab--margin-top;
    margin-bottom: $tabs--tab--margin-bottom;

    background: $tabs--tab--background-color;

    & .tab__title,
    & .tab__icon {
      font-weight: $tabs--tab--font-weight;
    }

    .tab__icon__title__container {
      @if $tabs--tab--horizontal {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        .tab__title {
          margin-top: 0;
          margin-left: 8px;
        }
      } @else {
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .tab__icon {
          display: block;
        }

        .tab__title {
          margin-top: 0;
          display: block;
        }
      }

      @if $tabs--title-icon-border-bottom {
        border-bottom: $tabs--title-icon-border-bottom !important;
      }
    }

    &__icon {
      color: $tabs--tab--color-icon-font;
      font-size: $tabs--tab--icon-font-size;
      transition: color 0.25s ease-in-out;
    }

    &__title {
      color: $tabs--tab--color;
      font-size: $tabs--tab--font-size;
      margin-top: $tabs--tab--text--margin-top;
      transition: color 0.25s ease-in-out;
    }

    &--active {
      border: $tabs--tab--border;
      border-bottom: $tabs--tab--border-bottom-active;
      background-color: $tabs--tab--background-color-active;

      .tab__title {
        color: $tabs--tab--color-active;
        font-weight: $tabs--tab--font-weight-active;
      }

      .tab__icon {
        color: $tabs--tab--color-icon-font-active;
        font-weight: $tabs--tab--font-weight-active;
      }

      .tab__icon__title__container {
        @if $tabs--title-icon-border-bottom--active {
          border-bottom: $tabs--title-icon-border-bottom--active !important;
        }
      }
    }

    &:hover {
      .tab__title {
        color: $tabs--tab--color-active;
      }

      .tab__icon {
        color: $tabs--tab--color-icon-font-active;
      }
    }
  }

  .tab-content {
    display: none;

    &--active {
      display: block;
    }
  }

  @if not map-get($map: $tabs--icons--enabled, $key: 'index') {
    &__header > .tab:first-child .tab__icon {
      display: none;
    }
  }

  @if not map-get($map: $tabs--icons--enabled, $key: 'search') {
    &__header > .tab:last-child .tab__icon {
      display: none;
    }
  }
}
