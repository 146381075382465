@use "sass:math";

img,
.reader-image {
  display: block;

  width: 100%;
  max-width: 100%;
  min-height: 1px;
  height: auto;

  margin: $images--image-margin;
}

.reader-image--full {
  @include bp(tablet) {
    max-width: 100%;
  };
}

.reader-image--half {
  @include bp(tablet) {
    max-width: math.div($images--image-max-width, 2);
  };
}
