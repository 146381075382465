.language__overlay {
  display: none;

  position: fixed;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  @if $languageoverlay-border-top {
    border-top: $languageoverlay-border-top;
  }

  flex-direction: column;
  justify-content: center;

  height: 100%;
  width: 100%;

  background-color: $languageoverlay-background-color;

  z-index: $languageoverlay-z-index;

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    a {
      display: block;
      width: 100%;

      padding: $languageoverlay--a-margin;

      color: $languageoverlay--a-color;
      text-decoration: none;
      text-align: center;

      font-family: $languageoverlay-font-family;
      font-weight: $languageoverlay-font-weight;
      font-size: $languageoverlay-font-size;

      &.selected {
        font-weight: $languageoverlay--selected--font-weight;
        color: $languageoverlay--selected--color;
        background-color: $languageoverlay--selected-background-color;
      }
    }
  }

  .icon--close {
    position: absolute;
    color: $languageoverlay--close-color;
    font-size: $languageoverlay--close-font-size;

    top: $languageoverlay--close-top;
    right: $languageoverlay--close-right;

    width: $languageoverlay--close-width;
    height: $languageoverlay--close-height;

    cursor: pointer;
  }
}

@at-root .language-overlay-shown {
  .language__overlay {
    display: flex !important;

    @include bp(tablet) {
      display: none !important;
    }
  }
}
