@at-root .reader__app {
  &.overlay-shown {
    overflow: hidden;

    .reader__overlay {
      display: flex;
    }
  }
}

// ====== Reader Body - Navigation ======

.sidebar {
  position: fixed;
  display: grid;
  grid-template-rows: auto 1fr;

  top: $header-height;

  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;

  opacity: 0;
  visibility: hidden;

  overflow-y: auto;

  &.small-header {
    top: $header--small-height;

    @include bp(tablet) {
      top: $header-height;
    }
  }
}

.reader__body {
  padding-top: $header-height;

  &.navigation-shown {
    .sidebar {
      opacity: 1;
      visibility: visible;
    }
  }

  &.small-header {
    padding-top: $header--small-height;
  }
}

@include bp(tablet) {
  .sidebar {
    position: fixed;

    width: $navigation--phone-max-width;

    // Overwrite menu behavior because of different way the navigation is shown on large devices. This resets the CSS instructions from the basic class definition.
    opacity: 1;
    visibility: visible;
  }

  .sidebar {
    left: -($navigation--phone-max-width + 8px);
    right: auto;
  }

  .reader__body {
    &.navigation-shown {
      .sidebar {
        left: 0;
        overflow: visible; // lipp: 06.05.2020 safari fix ... -_- macOS only
      }
    }
  }
}

// ====== Reader Body - Content ======

.reader__content__wrapper {
  display: none !important;
  height: calc(100vh - #{$header-height});
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &.active {
    display: block !important;
  }

  @supports (-webkit-touch-callout: none) {
    padding-bottom: 80px;
  }
}

.reader__content {
  position: relative;

  max-width: $content-max-width;

  padding: $content-padding-small;
  margin: 0 auto;

  z-index: $content-z-index;
}

@include bp(tablet) {
  .reader__content {
    padding: $content-padding-normal;
  }

  .reader__body {
    &.navigation-shown {
      .reader__content__wrapper {
        display: block;

        margin-left: $navigation--phone-max-width;
      }
    }
  }
}

// ====== Reader Overlay ======

.reader__overlay {
  position: fixed;
  margin: 0 !important;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: none;

  @media only screen and (max-width: 767px) {
    &.reader__body {
      padding-top: 0px;
    }
  }

  background-color: $modal-overlay-background-color;

  z-index: $modal-z-index;

  .overlay__container {
    display: flex;
    flex-direction: column;
    width: 100%;

    height: 100%;
    max-width: 100%;
    margin-bottom: 0 !important;

    background-color: $modal-background-color;
  }

  .header {
    position: relative;

    flex: 0 0 $modal-header-height;
    height: $modal-header-height;

    .icon-font {
      position: absolute;
      color: $modal--close-icon-color;
      top: $modal--close-icon-top;
      right: 25px;
      font-size: 25px;
      width: 25px;
      height: $modal--close-icon-height;

      cursor: pointer;
    }
  }

  .reader__content {
    flex: 0 1 auto;

    max-width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;

    overflow: auto;
  }

  .footer {
    flex: 0 0 $modal--footer-height;
    height: $modal--footer-height;
  }

  .reader__body {
    &.navigation-shown {
      .reader__content__wrapper {
        margin-left: 0;
      }
    }
  }
}

@include bp(tablet) {
  .reader__overlay {
    justify-content: center;
    align-items: center;

    padding: $content-padding-normal;

    .overlay__container {
      flex: 0 0 auto;
      height: auto;

      .reader__content {
        max-height: calc(80vh - 110px);
      }

      .header {
        flex: 0 0 (1.5 * $modal-header-height);
      }

      .footer {
        flex: 0 0 (1.5 * $modal--footer-height);
      }
    }
  }
}

@include bp(desktop) {
  .reader__overlay {
    .overlay__container {
      max-width: $reader--body-max-width;
    }
  }
}

// ====== Reader Body - Content - Row ======

@if $base--readerbody--row-activate {
  .row {
    margin-bottom: $content-bottom-margin;
  }

  @media screen and (max-width: 1200px) {
    .navigation-shown {
      .reader__content {
        .row {
          flex-direction: column;
        }

        .row--equal {
          .row__block {
            flex: 1 0 auto;
          }
        }
      }
    }
  }

  @if ($content--rows--equal--default-padding--enabled) {
    // make sure blocks are right aligned and have a space between
    @include bp(1201px) {
      .row.row--equal {
        & > *:first-child {
          padding-right: 10px;
        }

        & > *:only-child {
          padding-right: 0;
        }

        & > *:nth-child(2) {
          padding-left: 10px;

          img {
            align-self: flex-end;
          }
        }
      }
    }
  }

  @include bp(tablet) {
    .reader__content {
      .row {
        display: flex;
        flex-direction: row;
      }

      .row--equal {
        justify-content: space-between;

        .row__block {
          flex: 0 0 50%;

          display: flex;
          flex-direction: column;
          align-items: stretch;

          @if ($reader--body--image--half--margins-enabled) {
            &:first-child {
              margin-right: $reader--body--image--half--margins;
            }
            &:last-of-type {
              margin-left: $reader--body--image--half--margins;
            }
          }
        }
      }

      .row--left {
        justify-content: flex-start;

        .row__block {
          margin-right: 50px;
        }
      }
    }
  }
}
