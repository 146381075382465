@at-root .VersionNumber {
  $position: 20px;
  position: fixed;
  bottom: $position;
  right: $position;
  z-index: 8000;

  &-number {
    font-weight: bold;
  }
}

// atlassian trigger
.atlwdg-trigger {
  z-index: 10000;
}
