.reader__body {
  .overlay {
    &.order-form {
      &--shown {
        display: block;
      }
    }
  }
}

.order-form {
  .content {
    width: 100%;
    margin: 0 auto;
    @include multi-bp(padding-left, $grid-paddings-widths);
    @include multi-bp(padding-right, $grid-paddings-widths);
    @include multi-bp(max-width, $grid-breakpoint-max-widths);
    @include bp(1280px) {
      max-width: 1054px;
    }
    color: $order-form--color;

    @at-root .navigation-shown #{&} {
      padding-left: 40px;
      padding-right: 40px;
    }

    form {
      box-shadow: $order-form--box-shadow;
      margin-bottom: 30px;
      @include multi-bp(
        padding,
        (
          0px: 25px 10px,
          tablet: 48px
        )
      );
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;

    padding: 20px 0;

    input[type='submit'] {
      margin-left: 40px;
    }

    a {
      font-size: $order-form--link--font-size;
      text-decoration: none;

      &,
      &:active,
      &:visited {
        color: $order-form--link--color;
      }
    }

    .spinner__loader {
      display: inline-block;
      margin: 0 0 0 10px;
      align-self: 10px;
    }
  }

  label {
    display: block;

    width: 100%;
    margin-bottom: 5px;

    color: $order-form--label--color;

    font-size: $order-form--label--font-size;
    font-weight: $order-form--label--font-weight;
  }

  input {
    display: block;

    width: 100%;
    padding: 8px 15px;

    box-shadow: none;
    border: $order-form--input--border;
    border-radius: $order-form--input--border-radius;

    background-color: $order-form--input--background-color;

    color: $order-form--input--color;

    font-size: $order-form--input--font-size;
    font-weight: $order-form--input--font-weight;

    transition: border 160ms linear;

    &:focus,
    &:active {
      outline: none;

      border: $order-form--input--border-active;
    }

    &::-ms-expand {
      display: none;
    }
  }

  input[type='submit'] {
    max-width: 240px;
    min-width: 240px;
    border: 0;
    width: auto;
    height: auto;
    display: inline-block;
    padding: 11px 22px;
    background: $order-form--submit--background-color;
    border-radius: 5px;
    color: $order-form--submit--color;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    text-decoration: none;
    @include multi-bp(
      font-size,
      (
        0px: 16px,
        tablet: 18px
      )
    );

    &:hover {
      background: $order-form--submit--background-color-hover;
    }
  }

  .form {
    margin-top: 50px;
  }

  .form-group {
    margin-bottom: 15px;

    &__error {
      label {
        color: $order-form--error--label--color;
      }

      input {
        background-color: $order-form--error--input--background-color;
        border-color: $order-form--error--input--border-color;
      }

      & > .form-group__error-message {
        margin-top: 8px;
        color: $order-form--error-message--color;
        font-weight: 300;
      }
    }

    &--radio-buttons {
      .radio-buttons {
        display: flex;
        flex-direction: row;

        margin-bottom: 10px;

        & > div {
          flex: 0 1 auto;

          margin-right: 20px;
        }
      }

      input[type='radio'] {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }

    &__submit {
      margin-top: 50px;
    }
  }

  .form__copies {
    margin-bottom: 50px;
    max-width: 300px;
  }
}
