.embed-video {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}
